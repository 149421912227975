import { store } from '../common/rootReducer';
import * as userService from './userService';
import { UserActionTypes } from './userActionTypes';
import { Membership, User } from './userType';


export const getOrganizationMembers = (organizationId: number) => {
  return userService.getOrganizationMembers(organizationId).then((response) => {
    store.dispatch({
      type: UserActionTypes.SET_ORGANIZATION_MEMBERS,
      payload: response,
      id: organizationId,
    });
  });
};

export const addAccount = (user: User, orgId: number, account?: Membership) => {
  if(user.id && account) {
    return userService.addAccount(user.id, account).then((response) => {
      store.dispatch({
        type: UserActionTypes.ADD_ORGANIZATION_MEMBER,
        payload: response,
        orgId: orgId,
      });
      return response;
    });
  } else {
    return userService.createUser(user).then((response) => {
      store.dispatch({
        type: UserActionTypes.ADD_ORGANIZATION_MEMBER,
        payload: response,
        orgId: orgId,
      });
      return response;
    });
  }

};

export const editAccount = (account: Membership, orgId: number) => {
  return userService.editAccount(account).then((response) => {
    store.dispatch({
      type: UserActionTypes.UPDATE_ORGANIZATION_MEMBER,
      payload: response,
      orgId: orgId,
    });
  });
};

export const deleteAccount = (account: Membership, orgId: number) => {
  return userService.deleteAccount(account.id, account.userId!).then((response) => {
    store.dispatch({
      type: UserActionTypes.DELETE_ORGANIZATION_MEMBER,
      userId: account.userId,
      orgId: orgId,
    });
  });
};


export const editUser = (user: User, orgId: number) => {
  return userService.editUser(user).then((response) => {
    store.dispatch({
      type: UserActionTypes.UPDATE_ORGANIZATION_MEMBER,
      payload: response,
      orgId: orgId,
    });
  });
};

export const getUserDetails = (userId: number, orgId: number) => {
  return userService.getUserDetails(userId).then((response) => {
    store.dispatch({
      type: UserActionTypes.UPDATE_ORGANIZATION_MEMBER,
      payload: response,
      orgId: orgId,
    });
  });
};

