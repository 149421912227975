import { Identitfiable } from '../common/commonTypes';

export interface Capability extends Identitfiable {
  typeId?: number;
  userId?: number;
  orgId?: number;
  description?: string;
  pcs: number;
  validFrom?: moment.Moment;
  validUntil?: moment.Moment;
  notifyIn?: string;
}

export interface CapabilityOfferStats {
  typeId: number; // value set item ID in the 'ORG_CAPABILITIES' value set.
  totalPcs: number; // total number of capabilities of this type that is registered for the current organization (orgId param), regardless of validity
  // not set if the current organization doesn't have capabilities of this type
  validPcs: number; // number of capabilities of this type that is registered for the current organization (orgId param), which are valid in the timeframe of the event
  // not set if the current organization doesn't have capabilities of this type
  offeredToOtherEventsPcs: number; // number of capabilities of this type that the current organization has already offered for other events
  totalAccepted?: number; // the total number of capabilities of this type accepted for this event, only set if the current organization is the event's organizer
  totalDeclined?: number; // the total number of capabilities of this type declined for this event, only set if the current organization is the event's organizer
  totalOffered: number; // the total number of capabilities of this type accepted for this event
  offer?: CapabilityOffer; // set if the current organization has offered capabilities of this type for this event
  offeredToOtherEvents?: CapabilityOffer[]; // offers of the same capability type that the current organization made for other events
  offeredByOthers?: CapabilityOffer[]; // offers of the same capability type made by other organizations, only set if the current organization is the event's organizer
}


export interface CapabilityOffer {
  id: number;
  orgId: number;
  typeId: number;
  pcs: number;
  state: string; // OFFERED | ACCEPTED | DECLINED
  availableFrom: Date;
  availableUntil: Date;
  eventId?: number;
}


export const initialCapability: Capability = {
  id: 0,
  description: '',
  validFrom: undefined,
  validUntil: undefined,
  pcs: 1
};

export const CapabilityDateProperties: string[] = ['validFrom', 'validUntil'];
