import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormItem, useForm } from '../../../common/components/ant/form';
import { Input, TextArea } from '../../../common/components/ant/input';
import { ValueSetCode } from '../../../valueSets/valueSetType';
import { Capability } from '../../../capabilities/capabilityType';
import { ValueSetSelect } from '../../../common/components/valueSetSelectByCode';
import { InputNumber } from '../../../common/components/ant/InputNumber';
import { DatePicker, FormInstance } from 'antd';
import { Moment } from 'moment';
import { endDateValidation, startDateValidation } from '../../../utils/validation';
import { Modal } from '../../../common/components/ant/modal';

interface OrgCapabiltyActionsProps {
  showModal: boolean;
  handleSubmit: (values: Capability, form: FormInstance) => void;
  handleCancel: () => void;
  initialValues: Capability;
  title: string;
}

const OrgCapabiltyActions: React.FC<OrgCapabiltyActionsProps> = ({
  showModal,
  handleSubmit,
  handleCancel,
  initialValues,
  title,
}) => {
  const [form, setForm] = useState<FormInstance<any>>();

  return (
    <Modal
      destroyOnClose
      visible={showModal}
      onCancel={handleCancel}
      onOk={() => {
        if (form) {
          form.submit();
        }
      }}
      title={title}
    >
      <OrgCapabilityForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        setForm={setForm}
      />
    </Modal>
  );
};

export default OrgCapabiltyActions;

interface OrgCapabilityFormProps {
  handleSubmit: (values: Capability, form: FormInstance) => void;
  initialValues: Capability;
  setForm: React.Dispatch<React.SetStateAction<FormInstance<any> | undefined>>;
}
const OrgCapabilityForm: React.FC<OrgCapabilityFormProps> = ({
  initialValues,
  handleSubmit,
  setForm,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  useEffect(() => {
    setForm(form);
  }, [form]);

  const preProcessBeforeSubmit = (values: any) => {
    return {
      ...values,
      validFrom: values['validFrom'] ? values['validFrom'].startOf('day') : null,
      validUntil: values['validUntil'] ? values['validUntil'].endOf('day') : null,
    };
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={(values) => handleSubmit(preProcessBeforeSubmit(values), form)}
    >
      <FormItem
        name="typeId"
        label={t('capabilities.category')}
        rules={[{ required: true, message: t('common.validation.field-empty') }]}
      >
        <ValueSetSelect code={ValueSetCode.org} viewMode={initialValues.id ? true : false} />
      </FormItem>

      <FormItem name="description" label={t('common.description')}>
        <TextArea />
      </FormItem>
      <FormItem name="pcs" label={t('capabilities.pcs')}>
        <InputNumber min={1} />
      </FormItem>
      <FormItem name="validFrom" label={t('capabilities.validFrom')}>
        <DatePicker
          format="YYYY-MM-DD"
          style={{ width: '100%' }}
          disabledDate={(current: Moment) =>
            startDateValidation(current, form.getFieldValue('validUntil'))
          }
        />
      </FormItem>
      <FormItem name="validUntil" label={t('capabilities.validTo')}>
        <DatePicker
          format="YYYY-MM-DD"
          style={{ width: '100%' }}
          disabledDate={(current: Moment) =>
            endDateValidation(current, form.getFieldValue('validFrom'))
          }
        />
      </FormItem>
      <FormItem name="notifyIn" label={t('capabilities.notifyIn')}>
        <Input placeholder={'eg. 0,7,14,30'} />
      </FormItem>
    </Form>
  );
};
