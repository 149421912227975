import { Select as AntSelect, SelectProps } from 'antd';
import { ReactNode } from 'react';
import './antStyle.scss';

interface ISelectProps<T> extends SelectProps<T> {
  viewMode?: boolean;
  children: ReactNode;
}

export const Select: React.FC<ISelectProps<any>> = ({ viewMode, ...props }) => {
  return (
    <AntSelect
      size="large"
      {...props}
      disabled={viewMode}
      bordered={!viewMode}
      showArrow={!viewMode}
      className={`custom-select ${viewMode ? 'view-mode' : ''}  ${props.className}`}
    >
      {props.children}
    </AntSelect>
  );
};

export const { Option } = AntSelect;
