import { NormalizedState } from '../common/commonTypes';
import { eventActions, EventActionTypes } from './eventActionTypes';
import { EventSummary } from './eventType';

const initialState = {
  byId: {},
  allIds: []
};

export function closedEvents(
  state: NormalizedState<EventSummary[]> = initialState,
  action: eventActions,
) {
  switch (action.type) {
    case EventActionTypes.SET_CLOSED_EVENTS:
      return action.payload;

    default:
      return state;
  }
}
