import { Action } from 'redux';
import { AuthState, AuthStatus } from './authTypes';

export enum AuthActionTypes {
  SET_AUTH_SESSION_STATUS = 'SET_AUTH_SESSION_STATUS',
  SET_ACTIVE_SESSION = 'SET_ACTIVE_SESSION',
  USER_LOGOUT = 'USER_LOGOUT',
}

export interface SetAuthUserAction extends Action {
  type: AuthActionTypes.SET_ACTIVE_SESSION;
  payload: AuthState;
}

export interface SetAuthSessionStatusAction extends Action {
  type: AuthActionTypes.SET_AUTH_SESSION_STATUS;
  payload: AuthStatus;
}

export interface UserLogOutAction extends Action {
  type: AuthActionTypes.USER_LOGOUT;
}

export type authActions = SetAuthUserAction | SetAuthSessionStatusAction | UserLogOutAction;
