import { FormInstance, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentType } from '../../../../common/commonTypes';
import {
  CancelButton,
  EditButton,
  ResetButton,
  SaveButton,
} from '../../../../common/components/ant/button';
import { AccountsTable } from '../../../../common/components/editableTable';
import { TabBar } from '../../../../common/components/pageContent/tabBar';
import { UserForm } from '../../../../users/components/userActions';
import { Membership, roleTypes, User } from '../../../../users/userType';
import { RoleChecker } from '../../../../utils/roleChecker';

interface MemberDetailsProps {
  showModal: boolean;
  handleCancel: () => void;
  handleSubmitUser: (values: User, form: FormInstance<any> | undefined) => void;
  handleSubmitAccount: (values: Membership, form: FormInstance<any> | undefined) => Promise<void>;
  user: User;
}

const MemberDetails: React.FC<MemberDetailsProps> = ({
  showModal,
  handleCancel,
  user,
  handleSubmitUser,
  handleSubmitAccount,
}) => {
  const { t } = useTranslation();

  const tabs = [t('organizations.members.tabs.users'), t('organizations.members.tabs.accounts')];
  const [activeKey, setActiveKey] = useState(0);
  const [form, setForm] = useState<FormInstance<any>>();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (user.id) {
      // getUserDetails(user.id, selectedOrgId);
    }
  }, [user]);

  // const handleSubmitAccountsForm = (values: Membership) => {
  //   editAccount(values, selectedOrgId);
  // };

  const getModalFooter = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CancelButton
          onClick={() => {
            setActiveKey(0);
            handleCancel();
          }}
        />
        {activeKey === 0 &&
          (!editMode ? (
            <RoleChecker roles={[roleTypes.ADMIN]} checkPrimary orgId={user.primaryOrgId}>
              <EditButton
                onClick={() => {
                  setEditMode(true);
                }}
                text={t('common.edit')}
              />
            </RoleChecker>
          ) : (
            <div>
              <ResetButton
                onClick={() => {
                  if (form) {
                    console.log(form);
                    form.resetFields();
                  }
                  setEditMode(false);
                }}
              />
              <SaveButton
                onClick={() => {
                  if (form) {
                    form
                      .validateFields()
                      .then((values) => {
                        handleSubmitUser({ ...user, ...values }, form);
                        setEditMode(false);
                      })
                      .catch((info) => console.log(info));
                  }
                }}
                text={t('common.submit')}
              />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="member-details-container">
      <Modal
        visible={showModal}
        footer={getModalFooter()}
        destroyOnClose
        closable={false}
        className="details-modal"
        title={
          <TabBar
            tabs={tabs}
            setActiveKey={(index) => setActiveKey(index)}
            activeKey={activeKey}
            customClass="modal-tabs"
          />
        }
        width="600px"
      >
        <MemberDetailsForms
          user={user}
          activeKey={activeKey}
          editMode={editMode}
          handleSubmitAccount={handleSubmitAccount}
          setForm={setForm}
        />
      </Modal>
    </div>
  );
};

export default MemberDetails;

interface MemberDetailsFormsProps {
  user: User;
  activeKey: number;
  editMode: boolean;
  handleSubmitAccount: (values: Membership, form: FormInstance<any> | undefined) => Promise<void>;
  setForm: React.Dispatch<React.SetStateAction<FormInstance<any> | undefined>>;
}
const MemberDetailsForms: React.FC<MemberDetailsFormsProps> = ({
  user,
  activeKey,
  editMode,
  handleSubmitAccount,
  setForm,
}) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(0);

  const columns = [
    {
      title: t('account.alias'),
      className: 'alias-cell',
    },
    {
      title: t('account.roles'),
      className: 'roles-cell',
    },
    {
      title: '',
      className: 'actions-cell',
    },
  ];

  const components: ComponentType = {
    0: (
      <UserForm
        initialValues={{
          ...user,
          admin: user.globalRoles ? user.globalRoles.includes('admin') : false,
        }}
        handleSubmit={(values, form) => {}}
        editMode={editMode}
        setForm={setForm}
      />
    ),
    1: (
      <AccountsTable
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        columns={columns}
        dataSource={user.accounts!}
        handleSubmit={(values, form) => {
          handleSubmitAccount(values, form).then(() => {
            setSelectedRow(0);
          });
        }}
      />
    ),
  };
  return <div>{components[activeKey]}</div>;
};
