import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './loginScreenStyle.scss';
import { languages } from '../../common/commonTypes';

const LoginScreen: React.FC = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <div
        className="login-container"
        style={{
          backgroundImage: `url(/static/profile_bg.png)`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="login-form-wrapper">
          <div className="logo-container">
            <Link to="/login">
              <img src="static/icon.png" width={75} alt="event-manager"></img>
              <span>Event Manager</span>
            </Link>
          </div>

          {children}

          <div className="login-footer-container">
            {languages.map((language) => (
              <span
                className={language.value === i18n.language ? 'active' : ''}
                key={language.value}
                onClick={() => i18n.changeLanguage(language.value)}
              >
                {language.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
