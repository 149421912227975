import React, { useEffect, useState } from 'react';
import { addCapabilityToOrg, getOrgCapabilites } from '../../../capabilities/capabilityActions';
import { CreateButton } from '../../../common/components/ant/button';
import { useSelectedOrgId } from '../../orgState';
import { useTranslation } from 'react-i18next';
import CapabilityList from './capabilityList';
import { initialCapability } from '../../../capabilities/capabilityType';
import OrgCapabiltyActions from './orgCapabilityActions';
import { roleTypes } from '../../../users/userType';
import { RoleChecker } from '../../../utils/roleChecker';
import { FormInstance } from 'antd';
import { handleErrors } from '../../../utils/validation';

const Capabilities: React.FC = () => {
  const selectedOrgId = useSelectedOrgId();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (selectedOrgId) {
      getOrgCapabilites(selectedOrgId);
    }
  }, [selectedOrgId]);

  const { t } = useTranslation();

  const handleSubmit = (values: any, form: FormInstance) => {
    values.orgId = selectedOrgId;

    addCapabilityToOrg(values, selectedOrgId)
      .then(() => handleCancel())
      .catch((error) => {
        handleErrors(error.response.data, t, form, undefined, handleCancel);
      });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <OrgCapabiltyActions
        showModal={showModal}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        initialValues={initialCapability}
        title={t('capabilities.actions.addTitle')}
      />

      <div className="table-container">
        <RoleChecker roles={[roleTypes.ADMIN]}>
          <div className="new-item-container">
            <CreateButton onClick={() => setShowModal(true)} text={t('capabilities.actions.add')} />
          </div>
        </RoleChecker>

        <CapabilityList />
      </div>
    </>
  );
};

export default Capabilities;
