import { useEffect, FunctionComponent, useRef } from 'react';
import { createPortal } from 'react-dom';

interface PortalPropsInterface {
  selectorId: string;
}

const Portal: FunctionComponent<PortalPropsInterface> = ({ children, selectorId }) => {
  const div = useRef<any>(document.createElement('div'));

  useEffect(() => {
    const mount = document.getElementById(selectorId)!;
    const current = div.current;

    if (!mount) return;

    mount.appendChild(current);

    return () => {
      return mount.removeChild(current);
    };
  }, []);

  if (div.current) {
    if (children) {
      return createPortal(children, div.current);
    }
  }
  return null;
};

export default Portal;
