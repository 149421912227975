import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { ComponentType } from '../../common/commonTypes';
import Alert from '../../common/components/ant/alert';
import { Button, EditButton, ResetButton, SaveButton } from '../../common/components/ant/button';
import { Form, useForm } from '../../common/components/ant/form';
import Tag from '../../common/components/ant/tag';
import { ContentWrapper } from '../../common/components/pageContent/contentWrapper';
import { TabBar } from '../../common/components/pageContent/tabBar';
import { store } from '../../common/rootReducer';
import { setSelectedOrg } from '../../organizations/orgActions';
import { useOrganizations, useSelectedOrgId } from '../../organizations/orgState';
import { roleTypes } from '../../users/userType';
import { RoleChecker } from '../../utils/roleChecker';
import { handleErrors } from '../../utils/validation';
import { addEvent, getActiveEvents, getEventDetails, updateEvent } from '../eventActions';
import { EventActionTypes } from '../eventActionTypes';
import { useActiveEvents, useEventById } from '../eventState';
import {
  eventActionsByState,
  EventState,
  EventSummary,
  initialEventSummary,
  SendNotificationTo,
} from '../eventType';
import Capabilities from './capabilities';
import EventActionForm from './eventActionForm';
import RegistrationDetails from './registrationDetails';

const EventDetails: React.FC = () => {
  const { id, orgId } = useParams<{ id?: string; orgId?: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const activeEvents = useActiveEvents();

  const tabs = id ? [t('common.details'), t('events.participants'), t('events.capabilities')] : [];
  const [activeKey, setActiveKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');

  const onChangeSummary = (value: string) => {
    setTitle(value);
  };

  const components: ComponentType = {
    0: <Details onChangeSummary={onChangeSummary} />,
    1: <RegistrationDetails />,
    2: <Capabilities />,
  };

  useEffect(() => {
    if (orgId) {
      setSelectedOrg(parseInt(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    if (id) {
      if (orgId && (!activeEvents || activeEvents.allIds.length === 0)) {
        getActiveEvents(parseInt(orgId))
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            //TODO: ERROR HANDLING
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }

    return () => {
      store.dispatch({
        type: EventActionTypes.SET_CAPABILITIES,
        payload: { byId: {}, allIds: [] },
      });
      store.dispatch({
        type: EventActionTypes.SET_REGISTRATIONS,
        payload: {},
      });
    };
  }, []);

  return (
    <div className="event-details-container main-content-container">
      <div>
        <TabBar
          tabs={tabs}
          activeKey={activeKey}
          setActiveKey={(index) => setActiveKey(index)}
          customClass="event-details-tabs"
        >
          <div className="title-container">
            <Button
              type="link"
              onClick={() => history.push('/events')}
              style={{ color: '#00BCD4' }}
            >
              <ArrowLeftOutlined />
              {t('common.back')}
            </Button>
            <span className="title">{title} </span>
          </div>
        </TabBar>
        {!loading && <ContentWrapper>{components[activeKey]}</ContentWrapper>}
      </div>
    </div>
  );
};

export default EventDetails;

interface DetailsProps {
  onChangeSummary: (value: string) => void;
}

const Details: React.FC<DetailsProps> = ({ onChangeSummary }) => {
  const selectedOrgId = useSelectedOrgId();
  const history = useHistory();
  const [editMode, setEditMode] = useState(false);
  const { orgId, id } = useParams<{ id?: string; orgId?: string }>();
  const organizations = useOrganizations();
  const eventProps = useEventById(id ? parseInt(id) : 0);
  const event = eventProps.event;
  const eventIsActive = eventProps.isActive;
  const [form] = useForm();
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (event && event.organizerOrgId === parseInt(orgId!)) {
      getEventDetails(event.id);
    }
  }, []);

  // useEffect(() => {
  //   form.setFieldsValue(event);
  //   onChangeSummary(event ? event.summary : '');
  // }, [form, event]);

  const handleCancel = () => {
    form.resetFields();
    if (event) {
      setInitialEventValues(event);
      onChangeSummary(event.summary);
    }

    setEditMode(false);
  };

  const handleEdit = (values: any) => {
    setLoading(true);
    const invites = values.invites.map(
      (invite: { orgId: number; includeSubOrgs: null | 0 | 1 }) => {
        return { orgId: invite.orgId, children: invite.includeSubOrgs ? null : 0 };
      },
    );

    values.invites = invites;
    values.notifyAllBeforeStart =
      values.notifyAllBeforeStart === SendNotificationTo.ALL ? true : false;

    updateEvent({ ...values, id: event!.id, state: event!.state })
      .then(() => {
        setEditMode(false);
      })
      .catch((error) => {
        handleErrors(error.response.data, t, form, setErrorMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    values.organizerOrgId = selectedOrgId;
    values.openForRegistration = values.openForRegistration ? values.openForRegistration : false;

    const invites = values.invites.map(
      (invite: { orgId: number; includeSubOrgs: null | 0 | 1 }) => {
        return { orgId: invite.orgId, children: invite.includeSubOrgs ? null : 0 };
      },
    );

    values.invites = invites;
    values.notifyAllBeforeStart =
      values.notifyAllBeforeStart === SendNotificationTo.ALL ? true : false;

    values.state = values.startImmadiately ? EventState.IN_PROGRESS : EventState.PLANNED;

    addEvent(values)
      .then(() => {
        history.push('/events');
      })
      .catch((error) => {
        handleErrors(error.response.data, t, form, setErrorMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getActionsByState = (record: EventSummary) => {
    return eventActionsByState[record.state].map((code: string) => {
      return (
        <Button
          key={code}
          onClick={() => {
            updateEvent({ ...record, state: code });
          }}
        >
          {t(`events.state.actions.${code}`)}
        </Button>
      );
    });
  };

  const setInitialEventValues = (event: EventSummary) => {
    form.setFieldsValue({
      ...event,
      organizerOrgName: event.organizerOrgName
        ? event.organizerOrgName
        : organizations.byId[event.organizerOrgId].name
        ? organizations.byId[event.organizerOrgId].name
        : '',
      notifyAllBeforeStart: event.notifyAllBeforeStart
        ? SendNotificationTo.ALL
        : SendNotificationTo.ATTENDEES,
      invites: event.invites
        ? event.invites.map((el) => {
            return {
              id: el.id,
              orgId: el.orgId,
              includeSubOrgs: el.children === null || el.children === undefined ? true : false,
            };
          })
        : [],
    });
  };

  useEffect(() => {
    if (event) {
      setInitialEventValues(event);
      onChangeSummary(event.summary);
    }
  }, [form, event]);

  return (
    <>
      {id && !event ? (
        <p>{t('common.pageNotFound')}</p>
      ) : event ? (
        <>
          <Form
            // initialValues={}
            onFinish={(values) => handleEdit(values)}
            form={form}
            layout="vertical"
            requiredMark={editMode ? true : false}
          >
            {eventIsActive && (
              <RoleChecker
                roles={[roleTypes.ADMIN, roleTypes.ORGANIZER]}
                orgId={event.organizerOrgId}
              >
                <div className="actions-container">
                  <div className="event-state-actions">
                    <Tag className="custom-tag blue event-state-tag">
                      {t(`events.state.${event.state}`)}
                    </Tag>
                    <div className="action-buttons-container">{getActionsByState(event)}</div>
                  </div>
                  {(event.state !== EventState.COMPLETED || event.state !== EventState.CANCELLED) &&
                    (editMode ? (
                      <div className="action-buttons-container">
                        <ResetButton onClick={() => handleCancel()} />
                        <SaveButton htmlType="submit" loading={loading} />
                      </div>
                    ) : (
                      <EditButton onClick={() => setEditMode(true)} />
                    ))}
                </div>
              </RoleChecker>
            )}

            <div className="event-form-container">
              <div className={`error-container ${errorMsg ? 'visible' : ''}`}>
                {errorMsg && <Alert message={errorMsg} type="error" />}
              </div>
              <EventActionForm
                editMode={editMode}
                event={event}
                onChangeSummary={onChangeSummary}
                form={form}
              />
            </div>
          </Form>
        </>
      ) : (
        <>
          <Form
            onFinish={(values) => handleSubmit(values)}
            form={form}
            layout="vertical"
            initialValues={{
              openForRegistration: true,
              plannedStart: moment(new Date()),
              invites: [{ orgId: selectedOrgId, includeSubOrgs: true }],
              organizerOrgName: organizations.byId[selectedOrgId]
                ? organizations.byId[selectedOrgId].name
                : '',
            }}
          >
            <div className="actions-container save-button">
              <SaveButton htmlType="submit" loading={loading} />
            </div>
            <div className="event-form-container">
              <div className={`error-container ${errorMsg ? 'visible' : ''}`}>
                {errorMsg && <Alert message={errorMsg} type="error" />}
              </div>
              <EventActionForm editMode={true} onChangeSummary={onChangeSummary} form={form} />
            </div>
          </Form>
        </>
      )}
    </>
  );
};
