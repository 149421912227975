import { BellOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteOrgCapability, editOrgCapability } from '../../../capabilities/capabilityActions';
import { useOrgCapabilities } from '../../../capabilities/capabilityState';
import { Capability, initialCapability } from '../../../capabilities/capabilityType';
import OrgCapabiltyActions from './orgCapabilityActions';
import { DeleteButton, EditButton } from '../../../common/components/ant/button';
import { ValueSetCode, ValueSetItem } from '../../../valueSets/valueSetType';
import { useSelectedOrgId } from '../../orgState';
import { useValueSetItems } from '../../../valueSets/valueSetState';
import { roleTypes } from '../../../users/userType';
import { hasPermission } from '../../../utils/roleChecker';
import { useLoggedInUser } from '../../../auth/authState';
import Space from '../../../common/components/ant/space';
import Table from '../../../common/components/ant/table';
import { confirm } from '../../../common/components/ant/modal';
import { FormInstance } from 'antd';
import { handleErrors } from '../../../utils/validation';
import Tooltip from '../../../common/components/ant/tooltip';

const CapabilityList: React.FC = () => {
  const selectedOrgId = useSelectedOrgId();
  const orgCapabilitiesValueSetItems = useValueSetItems(ValueSetCode.org);
  const capabilities = useOrgCapabilities(selectedOrgId);
  const loggedInUser = useLoggedInUser();

  const [showModal, setShowModal] = useState(false);
  const [selectedCapability, setSelectedCapability] = useState(initialCapability);

  const { t } = useTranslation();

  const handleSubmit = (values: any, form: FormInstance) => {
    const mergedValues = {
      ...selectedCapability,
      ...values,
    };

    const submitValues = {
      ...mergedValues,
      // validFrom: values.validFromTo[0],
      // validUntil: values.validFromTo[1],
      orgid: selectedOrgId,
    };

    // delete submitValues.validFromTo;

    editOrgCapability(submitValues, selectedOrgId)
      .then(() => handleCancel())
      .catch((error) => {
        handleErrors(error.response.data, t, form, handleCancel);
      });

    // editOrgCapability(submitValues, selectedOrgId);
    // handleCancel();
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: t('capabilities.category'),
      key: 'label',
      render: (record: Capability) => {
        const valueSetItem = orgCapabilitiesValueSetItems
          ? orgCapabilitiesValueSetItems.find(
              (valueSet: ValueSetItem) => valueSet.id === record.typeId,
            )
          : null;
        return <>{valueSetItem && valueSetItem.label}</>;
      },
    },
    {
      title: t('capabilities.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('capabilities.validFrom'),
      key: 'validFrom',
      render: (record: Capability) => {
        if (record.validFrom) {
          return <>{moment(record.validFrom).format('YYYY-MM-DD')} </>;
        }
      },
    },
    {
      title: t('capabilities.validTo'),
      key: 'validTo',
      render: (record: Capability) => {
        if (record.validUntil) {
          return (
            <>
              {moment(record.validUntil).format('YYYY-MM-DD')}{' '}
              {record.notifyIn && (
                <Tooltip
                  //                  overlay={`Notify org admins ${record.notifyIn} days before the capability expires`}
                  overlay={t('capabilities.notification', { notifyIn: record.notifyIn })}
                >
                  <BellOutlined style={{ fontSize: '18px' }} />
                </Tooltip>
              )}
            </>
          );
        }
      },
    },
    {
      title: t('capabilities.pcs'),
      dataIndex: 'pcs',
      key: 'pcs',
    },

    hasPermission([roleTypes.ADMIN], loggedInUser, selectedOrgId)
      ? {
          key: 'action',
          render: (record: Capability) => (
            <Space size="middle">
              <EditButton
                onClick={() => {
                  setSelectedCapability(record);
                  setShowModal(true);
                }}
              />
              <DeleteButton onClick={() => showConfirm(record)} />
            </Space>
          ),
        }
      : {},
  ];

  const showConfirm = (capability: Capability) => {
    confirm({
      title: t('capabilities.deleteMessage'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteOrgCapability(capability.id, selectedOrgId);
      },
    });
  };

  return (
    <>
      <OrgCapabiltyActions
        showModal={showModal}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        initialValues={selectedCapability}
        title={t('capabilities.actions.edit')}
      />
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={capabilities}
        pagination={false}
      />
    </>
  );
};

export default CapabilityList;
