import { ExclamationCircleOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoggedInUser } from '../../auth/authState';
import { InfoButton, DeleteButton, Button } from '../../common/components/ant/button';
import MemberDetails from '../../organizations/components/members/details';
import PersonalCapabilities from '../../organizations/components/members/details/personalCapabilities';
import { useSelectedOrgId } from '../../organizations/orgState';
import { hasPermission, RoleChecker } from '../../utils/roleChecker';
import { editUser, deleteAccount } from '../userActions';
import { User, Membership, initialUser, roleTypes } from '../userType';
import { confirm } from '../../common/components/ant/modal';
import { FormInstance } from 'antd';
import Tooltip from '../../common/components/ant/tooltip';
import Tag from '../../common/components/ant/tag';
import Space from '../../common/components/ant/space';
import Table from '../../common/components/ant/table';

interface UserListProps {
  users: User[];
  handleSubmitUser: (values: User, form: FormInstance<any> | undefined) => void;
  handleSubmitAccount: (values: Membership, form: FormInstance<any> | undefined) => Promise<void>;
  isOrgContext: boolean;
  emptyText?: string;
}

const UserList: React.FC<UserListProps> = ({
  users,
  handleSubmitUser,
  handleSubmitAccount,
  isOrgContext,
  emptyText,
}) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showCapabilitiesModal, setShowCapabilitiesModal] = useState(false);
  const selectedOrgId = useSelectedOrgId();
  const loggedInUser = useLoggedInUser();
  const [selectedUser, setSelectedUser] = useState<User>(initialUser);
  const { t } = useTranslation();

  const handleCancel = () => {
    // setSelectedUser(initialUser);
    setShowDetailsModal(false);
  };

  const primaryOrgAction = (user: User) => {
    if (user.primaryOrgLocked && user.primaryOrgId === selectedOrgId) {
      return (
        <Tooltip title={t('users.unlock')}>
          <Button
            style={{ border: 'none', background: 'transparent', boxShadow: 'none' }}
            onClick={() => editUser({ ...user, primaryOrgLocked: false }, selectedOrgId)}
            icon={<LockOutlined />}
          />
        </Tooltip>
      );
    }
    if (!user.primaryOrgLocked) {
      return (
        <Tooltip title={t('users.lock')}>
          <Button
            style={{ border: 'none', background: 'transparent', boxShadow: 'none' }}
            onClick={() =>
              editUser(
                { ...user, primaryOrgLocked: true, primaryOrgId: selectedOrgId },
                selectedOrgId,
              )
            }
            icon={<UnlockOutlined />}
          />
        </Tooltip>
      );
    }
  };

  const showConfirm = (user: User) => {
    confirm({
      title: t('users.delete-account-message'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const accounts = user.accounts!;
        const account = accounts.find((acc: Membership) => acc.orgId === selectedOrgId);
        deleteAccount(account!, selectedOrgId);
      },
    });
  };

  const columns = [
    isOrgContext
      ? {
          title: t('common.account'),
          dataIndex: 'accounts',
          key: 'account',
          render: (accounts: Membership[]) => (
            <>
              {accounts.map((account: Membership) => {
                if (account.orgId === selectedOrgId)
                  return (
                    <Tag className={account.primary ? 'blue' : ''} key={account.id}>
                      {account.alias}
                    </Tag>
                  );
              })}
            </>
          ),
        }
      : {
          title: t('account.primary'),
          dataIndex: 'accounts',
          key: 'account',
          render: (accounts: Membership[]) => (
            <>
              {accounts.map((account: Membership) => {
                if (account.primary)
                  return (
                    <Tag className="blue" key={account.id}>
                      {account.alias}
                    </Tag>
                  );
              })}
            </>
          ),
        },
    {
      title: t('common.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('common.name'),
      key: 'name',
      render: (user: User) => {
        return <>{`${user.lastName} ${user.firstName}`}</>;
      },
    },
    {
      title: t('users.otherAccounts'),
      dataIndex: 'accounts',
      key: 'account',
      render: (accounts: Membership[]) => (
        <>
          {accounts.map((account: Membership) => {
            if (account.orgId !== selectedOrgId)
              return (
                <Tag className={account.primary ? 'blue' : ''} key={account.id}>
                  {account.alias}
                </Tag>
              );
          })}
        </>
      ),
    },
    hasPermission([roleTypes.ADMIN], loggedInUser, selectedOrgId)
      ? {
          key: 'action',
          render: (record: User) => (
            <RoleChecker roles={[roleTypes.ADMIN]}>
              <Space size="middle">
                <InfoButton
                  onClick={() => {
                    setSelectedUser(record);
                    setShowDetailsModal(true);
                  }}
                  text={t('common.details')}
                />
                <InfoButton
                  className="info"
                  onClick={() => {
                    setSelectedUser(record);
                    setShowCapabilitiesModal(true);
                  }}
                  text={t('events.capabilities')}
                />
                {isOrgContext && (
                  <>
                    <DeleteButton onClick={() => showConfirm(record)} />
                    {primaryOrgAction(record)}
                  </>
                )}
              </Space>
            </RoleChecker>
          ),
        }
      : {},
  ];

  return (
    <>
      <MemberDetails
        showModal={showDetailsModal}
        handleCancel={handleCancel}
        user={selectedUser}
        handleSubmitUser={handleSubmitUser}
        handleSubmitAccount={handleSubmitAccount}
      />

      <PersonalCapabilities
        showModal={showCapabilitiesModal}
        handleCancel={() => setShowCapabilitiesModal(false)}
        user={selectedUser}
      />
      <div className="table-container">
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={users}
          pagination={false}
          locale={{ emptyText: emptyText }}
        />
      </div>
    </>
  );
};

export default UserList;
