import { initialUser } from '../users/userType';
import { authActions, AuthActionTypes } from './authActionTypes';
import { AuthState, AuthStatus } from './authTypes';

const initialState = {
  status: AuthStatus.NOT_AUTHENTICATED,
  user: initialUser,
};

export function auth(state: AuthState = initialState, action: authActions) {
  switch (action.type) {
    case AuthActionTypes.SET_ACTIVE_SESSION:
      return action.payload.status === AuthStatus.AUTHENTICATED
        ? {
            status: AuthStatus.AUTHENTICATED,
            user: action.payload.user,
          }
        : {
            status: AuthStatus.NOT_AUTHENTICATED,
          };
    case AuthActionTypes.SET_AUTH_SESSION_STATUS:
      return {
        status: action.payload,
      };

    default:
      return state;
  }
}
