import { DatePicker as AntDatePicker } from 'antd';
import './antStyle.scss';

export const DatePicker = (props: any) => {
  return (
    <AntDatePicker
      size="large"
      {...props}
      disabled={props.viewMode}
      bordered={!props.viewMode}
      className={`custom-date-picker ${props.viewMode ? 'view-mode' : ''}  ${
        props.className ? props.className : ''
      }`}
    />
  );
};

export const { RangePicker } = AntDatePicker;
