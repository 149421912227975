import { FunctionComponent } from 'react';
import { useLoggedInUser } from '../auth/authState';
import { useSelectedOrgId } from '../organizations/orgState';
import { useOrganizationMembers } from '../users/userState';
import { Membership, User } from '../users/userType';

export const hasPermission = (
  roles: string[],
  loggedInUser: User,
  selectedOrgId: number,
  orgId?: number,
  members?: User[],
) => {
  if (loggedInUser.globalRoles && loggedInUser.globalRoles.includes('admin')) {
    return true;
  }
  if (loggedInUser.accounts) {
    const account = loggedInUser.accounts.find((account) => account.orgId === selectedOrgId);
    if (account) {
      const hasRole = account.roles.some((element) => roles.includes(element)) ? true : false;
      if (orgId) {
        return hasRole && orgId === selectedOrgId;
      } else {
        return hasRole;
      }
    } else {
      if (members) {
        const isAdminInSelectedOrg = members.some((member: User) => {
          const accForSelectedOrg = member.accounts!.find(
            (acc: Membership) => acc.orgId === selectedOrgId,
          );
          if (accForSelectedOrg) {
            return accForSelectedOrg.roles.includes('admin');
          } else {
            return false;
          }
        });

        return !isAdminInSelectedOrg;
      } else {
        return true;
      }
    }
  }
};

interface RoleCheckerProps {
  roles: string[];
  showError?: boolean;
  checkPrimary?: boolean;
  orgId?: number;
}

export const RoleChecker: FunctionComponent<RoleCheckerProps> = ({
  children,
  roles,
  orgId,
  showError,
}) => {
  const loggedInUser = useLoggedInUser();
  const selectedOrgId = useSelectedOrgId();
  const members = useOrganizationMembers(selectedOrgId);

  return <>{hasPermission(roles, loggedInUser, selectedOrgId, orgId, members) && children}</>;
};
