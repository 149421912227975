import { FormInstance } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addCapability,
  deleteCapability,
  editCapability,
} from '../../../../capabilities/capabilityService';
import { Capability } from '../../../../capabilities/capabilityType';
import { Button } from '../../../../common/components/ant/button';
import { Modal } from '../../../../common/components/ant/modal';

import { PersonalCapabilitiesTable } from '../../../../common/components/editableTable';

import { getUserDetails } from '../../../../users/userService';
import { User } from '../../../../users/userType';
import { handleErrors } from '../../../../utils/validation';

interface PersonalCapabilitiesProps {
  showModal: boolean;
  handleCancel: () => void;
  user: User;
}

const PersonalCapabilities: React.FC<PersonalCapabilitiesProps> = ({
  showModal,
  handleCancel,
  user,
}) => {
  const { t } = useTranslation();

  const [userCapabilities, setUserCapabilites] = useState<Capability[]>([]);
  const [selectedRow, setSelectedRow] = useState(0);

  useEffect(() => {
    if (user.id)
      getUserDetails(user.id).then((response) => {
        setUserCapabilites(response.capabilities);
      });
  }, [user]);

  const columns = [
    {
      title: t('capabilities.category'),
      className: 'label-cell',
    },
    {
      title: t('capabilities.description'),
      className: 'description-cell',
    },
    {
      title: t('capabilities.validFrom'),
      className: 'valid-from-cell',
    },
    {
      title: t('capabilities.validTo'),
      className: 'valid-to-cell',
    },
    {
      title: t('capabilities.notifyIn'),
      className: 'notify-cell',
    },
    {
      title: '',
      className: 'actions-cell',
    },
  ];

  const handleDelete = (id: number) => {
    deleteCapability(id).then(() => {
      setUserCapabilites(userCapabilities.filter((cap) => cap.id !== id));
    });
  };

  const preProcessBeforeSubmit = (values: any) => {
    return {
      ...values,
      validFrom: values['validFrom'] ? values['validFrom'].startOf('day') : null,
      validUntil: values['validUntil'] ? values['validUntil'].endOf('day') : null,
    };
  };

  const handleEdit = (values: any, form: FormInstance<any>) => {
    editCapability(preProcessBeforeSubmit(values))
      .then((response) => {
        setUserCapabilites(
          userCapabilities.map((capability) => {
            if (capability.id === response.id) {
              return response;
            } else {
              return capability;
            }
          }),
        );
        setSelectedRow(0);
      })
      .catch((error) => handleErrors(error.response.data, t, form));
  };

  const handleSubmit = (values: any, form: FormInstance<any>) => {
    console.log(form.getFieldsValue());
    addCapability({ ...preProcessBeforeSubmit(values), userId: user.id })
      .then((response) => {
        setUserCapabilites(userCapabilities.concat(response));
        form.resetFields();
      })
      .catch((error) => handleErrors(error.response.data, t, form));
  };

  return (
    <div className="member-details-container">
      <Modal
        onCancel={handleCancel}
        visible={showModal}
        footer={<Button onClick={() => handleCancel()}>{t('common.cancel')} </Button>}
        destroyOnClose
        closable
        className="capabilities-modal"
        title={t('capabilities.myCapabilites')}
        width="1100px"
      >
        <PersonalCapabilitiesTable
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          columns={columns}
          dataSource={userCapabilities}
          handleDelete={handleDelete}
          handleSubmit={handleEdit}
          handleSubmitNewRow={handleSubmit}
          primaryOrgId={user.primaryOrgId}
        />
      </Modal>
    </div>
  );
};

export default PersonalCapabilities;
