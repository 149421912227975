import { store } from '../common/rootReducer';
import { ValueSetActionTypes } from './valueSetActionTypes';
import * as valueSetService from './valueSetService';

export const getValueSets = () => {
  return valueSetService.getValueSets().then((response) => {
    store.dispatch({
      type: ValueSetActionTypes.SET_VALUE_SETS,
      payload: response,
    });
  });
};
