import { ValueSetActions, ValueSetActionTypes } from './valueSetActionTypes';
import { ValueSet } from './valueSetType';

export function valueSets(state: ValueSet[] = [], action: ValueSetActions) {
  switch (action.type) {
    case ValueSetActionTypes.SET_VALUE_SETS:
      return action.payload;

    default:
      return state;
  }
}
