import { Action } from 'redux';
import { User } from './userType';

export enum UserActionTypes {
  SET_USERS = 'SET_USERS',
  SET_ORGANIZATION_MEMBERS = 'SET_ORGANIZATION_MEMBERS',
  UPDATE_ORGANIZATION_MEMBER = 'UPDATE_ORGANIZATION_MEMBER',
  ADD_ORGANIZATION_MEMBER = 'ADD_ORGANIZATION_MEMBER',
  DELETE_ORGANIZATION_MEMBER = 'DELETE_ORGANIZATION_MEMBER',
  ADD_CAPABILITY = 'ADD_CAPABILITY',
}

export interface SetUsersAction extends Action {
  type: UserActionTypes.SET_USERS;
  payload: User[];
}
export interface UpdateOrgMembers extends Action {
  type: UserActionTypes.SET_ORGANIZATION_MEMBERS;
  payload: User[];
  id: number;
}

export interface UpdateOrgMember extends Action {
  type: UserActionTypes.UPDATE_ORGANIZATION_MEMBER;
  payload: User;
  orgId: number;
}

export interface CreateOrgMember extends Action {
  type: UserActionTypes.ADD_ORGANIZATION_MEMBER;
  payload: User;
  orgId: number;
}

export interface DeleteOrgMember extends Action {
  type: UserActionTypes.DELETE_ORGANIZATION_MEMBER;
  userId: number;
  orgId: number;
}

export interface addCapability extends Action {
  type: UserActionTypes.ADD_CAPABILITY;
  payload: User;
}

export type userActions =
  | SetUsersAction
  | UpdateOrgMembers
  | UpdateOrgMember
  | CreateOrgMember
  | DeleteOrgMember
  | addCapability;
