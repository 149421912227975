import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../common/components/ant/button';
import { DatePicker } from '../../common/components/ant/DatePicker';
import { FormItem, FormList } from '../../common/components/ant/form';
import { Input, TextArea } from '../../common/components/ant/input';
import Switch from '../../common/components/ant/switch';
import { ValueSetSelect } from '../../common/components/valueSetSelectByCode';
import { useValueSetItems } from '../../valueSets/valueSetState';
import { ValueSetCode, ValueSetItem } from '../../valueSets/valueSetType';
import { EventSummary, SendNotificationTo } from '../eventType';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CaretRightOutlined } from '@ant-design/icons';
import { useOrganizations, useSelectedOrgId } from '../../organizations/orgState';
import TreeSelect from '../../common/components/ant/treeSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Select, Option } from '../../common/components/ant/select';
import { InputNumber } from '../../common/components/ant/InputNumber';
import { FormInstance } from 'antd';

interface EventActionFormProps {
  editMode: boolean;
  onChangeSummary: (value: string) => void;
  event?: EventSummary;
  form: FormInstance<any>;
}

const EventActionForm: React.FC<EventActionFormProps> = ({
  editMode,
  event,
  onChangeSummary,
  form,
}) => {
  const { t } = useTranslation();
  const [typeId, setTypeId] = useState(event ? event.typeId : 0);
  const valueSetItems = useValueSetItems(ValueSetCode.eventTypes);
  const organizations = useOrganizations();
  const selectedOrgId = useSelectedOrgId();

  const getIcon = () => {
    if (typeId) {
      const valueSetItem = valueSetItems
        ? valueSetItems.find((valueSet: ValueSetItem) => valueSet.id === typeId)
        : null;
      return (
        <>
          {valueSetItem && (
            <FontAwesomeIcon
              icon={valueSetItem.icon! as IconName}
              className="event-type-icon details-screen"
            />
          )}
        </>
      );
    } else {
      return (
        <FontAwesomeIcon icon={'question' as IconName} className="event-type-icon details-screen" />
      );
    }
  };

  const normalizeOrgTree = (orgId: number): any[] => {
    const org = organizations.byId[orgId];
    if (org.children) {
      return org.children.map((orgId) => {
        return {
          title: organizations.byId[orgId].name,
          value: orgId,
          children: normalizeOrgTree(orgId),
        };
      });
    }

    return [];
  };

  const childrenTree = organizations.byId[selectedOrgId]
    ? organizations.byId[selectedOrgId].children.map((orgId) => {
        return {
          title: organizations.byId[orgId].name,
          value: orgId,
          children: normalizeOrgTree(orgId),
        };
      })
    : [];

  const treeData: any = [
    {
      title: organizations.byId[selectedOrgId] ? organizations.byId[selectedOrgId].name : '',
      value: selectedOrgId,
      children: childrenTree,
    },
  ];

  return (
    <>
      <div className="event-icon-container">{getIcon()}</div>
      <div style={{ width: '100%' }}>
        <div className="two-column-flex-form">
          <div>
            <FormItem name="organizerOrgName" label={t('events.organizer')}>
              <Input viewMode={true} className="with-background" />
            </FormItem>
            <FormItem name="summary" label={t('events.summary')} rules={[{ required: true }]}>
              <Input
                viewMode={!editMode}
                className="with-background"
                onChange={(e) => onChangeSummary(e.target.value)}
              />
            </FormItem>

            <FormItem name="typeId" label={t('common.type')} rules={[{ required: true }]}>
              <ValueSetSelect
                code={ValueSetCode.eventTypes}
                viewMode={!editMode || event !== undefined}
                onChange={(value: number) => setTypeId(value)}
                className="with-background"
              />
            </FormItem>
            <div className="two-column-flex-form">
              <div>
                <FormItem
                  name="plannedStart"
                  label={t('common.start')}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    style={{ width: '100%' }}
                    viewMode={!editMode}
                    allowClear={false}
                    className="with-background"
                  />
                </FormItem>
              </div>
              <div>
                <FormItem name="plannedEnd" label={t('common.end')} rules={[{ required: true }]}>
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    style={{ width: '100%' }}
                    viewMode={!editMode}
                    allowClear={false}
                    className="with-background"
                  />
                </FormItem>
              </div>
            </div>
            <FormItem
              name="openForRegistration"
              label={t('events.openForRegistration')}
              valuePropName="checked"
            >
              <Switch viewMode={!editMode} />
            </FormItem>

            {!event && (
              <FormItem
                name="startImmadiately"
                valuePropName="checked"
                label={t('events.startImmadiately')}
              >
                <Switch viewMode={!editMode} />
              </FormItem>
            )}
          </div>

          <div className="form-description-container">
            <FormItem name="description" label={t('events.description')}>
              <TextArea viewMode={!editMode} className="with-background" />
            </FormItem>
          </div>
        </div>
        <div className="invites-container">
          <FormList name="invites">
            {(fields: any[], { add, remove }: any) => (
              <>
                {fields.map((field) => (
                  <div key={field.fieldKey} className="invite-item-container">
                    <div className="two-column-flex-form">
                      <div>
                        {console.log(selectedOrgId)}
                        <FormItem
                          {...field}
                          label={field.fieldKey === 0 ? t('events.inviteOrg') : ''}
                          name={[field.name, 'orgId']}
                          fieldKey={[field.fieldKey, 'orgId']}
                        >
                          <TreeSelect
                            size="large"
                            dropdownClassName="dropdown"
                            treeData={treeData}
                            switcherIcon={<CaretRightOutlined />}
                            treeDefaultExpandAll
                            dropdownMatchSelectWidth={false}
                            viewMode={!editMode}
                            className="with-background"
                          />
                        </FormItem>
                      </div>
                      <div>
                        <FormItem
                          name={[field.name, 'includeSubOrgs']}
                          fieldKey={[field.fieldKey, 'includeSubOrgs']}
                          label={field.fieldKey === 0 ? t('events.includeSubOrgs') : ''}
                          valuePropName="checked"
                        >
                          <Switch viewMode={!editMode} />
                        </FormItem>
                      </div>
                    </div>
                    {editMode && (
                      <MinusCircleOutlined
                        className="minus-button"
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </div>
                ))}
                {editMode && (
                  <FormItem>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('events.addInvite')}
                    </Button>
                  </FormItem>
                )}
              </>
            )}
          </FormList>
        </div>
        <div className="notification-container">
          <FormItem name="notifyBeforeStart" label={t('events.notifyBeforeStart')}>
            <InputNumber viewMode={!editMode} className="with-background" min={0} />
          </FormItem>

          <FormItem name="notifyAllBeforeStart" label={t('events.notifyAllBeforeStart')}>
            <Select viewMode={!editMode} style={{ minWidth: '180px' }} className="with-background">
              <Option key={SendNotificationTo.ALL} value={SendNotificationTo.ALL}>
                {t('events.all')}
              </Option>
              <Option key={SendNotificationTo.ATTENDEES} value={SendNotificationTo.ATTENDEES}>
                {t('events.attendees')}
              </Option>
            </Select>
          </FormItem>

          <FormItem
            name="notifyWhenCompleted"
            label={t('events.notifyWhenCompleted')}
            valuePropName="checked"
          >
            <Switch viewMode={!editMode} />
          </FormItem>

          {event && event.id && (
            <FormItem name="sendNotification" label={t('events.sendNotification')}>
              <Select viewMode={!editMode} className="with-background">
                <Option key="all" value={SendNotificationTo.ALL}>
                  {t('events.all')}
                </Option>
                <Option key="attendees" value={SendNotificationTo.ATTENDEES}>
                  {t('events.attendees')}
                </Option>
              </Select>
            </FormItem>
          )}
        </div>
      </div>
    </>
  );
};

export default EventActionForm;
