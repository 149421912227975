import { FormInstance } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoggedInUser } from '../../auth/authState';
import { languages } from '../../common/commonTypes';
import { DatePicker } from '../../common/components/ant/DatePicker';
import { Form, FormItem, useForm } from '../../common/components/ant/form';
import { Input } from '../../common/components/ant/input';
import { Modal } from '../../common/components/ant/modal';
import { Select, Option } from '../../common/components/ant/select';
import Switch from '../../common/components/ant/switch';
import { roleTypes, User } from '../userType';
import './userStyle.scss';

interface UserActionsProps {
  showModal: boolean;
  handleSubmit: (values: User, form: FormInstance) => void;
  handleCancel: () => void;
  initialValues: User;
  title: string;
  editMode?: boolean;
  hideEmailField?: boolean;
}

export const UserActions: React.FC<UserActionsProps> = ({
  editMode,
  showModal,
  handleSubmit,
  handleCancel,
  initialValues,
  title,
  hideEmailField,
}) => {
  const [form, setForm] = useState<FormInstance<any>>();

  return (
    <Modal
      destroyOnClose
      visible={showModal}
      onCancel={handleCancel}
      onOk={() => {
        if (form) {
          form.submit();
        }
      }}
      title={title}
    >
      <UserForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        setForm={setForm}
        editMode={editMode}
        hideEmailField={hideEmailField}
      />
    </Modal>
  );
};

interface UserFormProps {
  handleSubmit: (values: User, form: FormInstance) => void;
  initialValues: User;
  setForm?: React.Dispatch<React.SetStateAction<FormInstance<any> | undefined>>;
  editMode?: boolean;
  hideEmailField?: boolean;
}
export const UserForm: React.FC<UserFormProps> = ({
  initialValues,
  handleSubmit,
  setForm,
  editMode,
  hideEmailField,
  children,
}) => {
  const loggedInUser = useLoggedInUser();

  const { t } = useTranslation();
  const [form] = useForm();

  useEffect(() => {
    if (setForm) {
      setForm(form);
    }
  }, [form]);

  const isCreatingUser = initialValues.id ? false : true;

  const validatePassword = (value: string) => {
    return /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value) && value.length >= 8;
  };

  const preProcessBeforeSubmit = (values: User) => {
    if (!values.password) {
      delete values.password;
    }
    return {
      ...values,
      globalRoles: values.admin ? ['admin'] : [],
    };
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={(values) => handleSubmit(preProcessBeforeSubmit(values), form)}
    >
      <div className="user-form">
        <div className="two-column-flex-form">
          <div>
            <FormItem name="firstName" label={t('common.firstName')} style={{ padding: 0 }}>
              <Input viewMode={!editMode} className="with-background" />
            </FormItem>
          </div>
          <div>
            <FormItem name="lastName" label={t('common.lastName')}>
              <Input viewMode={!editMode} className="with-background" />
            </FormItem>
          </div>
        </div>

        <FormItem
          name="email"
          label={t('common.email')}
          rules={[{ required: true }, { type: 'email' }]}
        >
          <Input viewMode={!editMode || hideEmailField} className="with-background" />
        </FormItem>

        <FormItem
          name="password"
          label={t('login.password')}
          required={isCreatingUser}
          rules={[
            () => ({
              validator(_, value) {
                if (isCreatingUser) {
                  if (value && validatePassword(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('login.error.password-policy')));
                } else {
                  if (!value || validatePassword(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('login.error.password-policy')));
                }
              },
            }),
          ]}
        >
          <Input
            viewMode={!editMode}
            type="password"
            className="with-background"
            placeholder="*********"
          />
        </FormItem>

        <div className="two-column-flex-form">
          <div>
            <FormItem name="dateOfBirth" label={t('common.dateOfBirth')}>
              <DatePicker
                viewMode={!editMode}
                placeholder={editMode ? 'YYYY-MM-DD' : ''}
                className="with-background"
              />
            </FormItem>
          </div>
          <div>
            <FormItem name="language" label={t('common.language')}>
              <Select viewMode={!editMode} className="with-background">
                {languages.map((lng) => (
                  <Option key={lng.value} value={lng.value}>
                    {lng.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </div>
        </div>

        <div className="two-column-flex-form">
          <div style={{ paddingLeft: 0 }}>
            <FormItem
              name="notifyViaEmail"
              valuePropName="checked"
              label={t('users.notifyViaEmail')}
            >
              <Switch viewMode={!editMode} />
            </FormItem>
          </div>
          {loggedInUser.globalRoles &&
            loggedInUser.globalRoles.includes(roleTypes.ADMIN) &&
            loggedInUser.id !== initialValues.id && (
              <div>
                <FormItem name="admin" valuePropName="checked" label={t('users.admin')}>
                  <Switch viewMode={!editMode} />
                </FormItem>
              </div>
            )}
        </div>
      </div>
      {children}
    </Form>
  );
};

// interface UserFormProps {
//   editMode: boolean;
//   createUser?: boolean;
//   userId?: number;
// }
// const UserFormItems: React.FC<UserFormProps> = ({ editMode, createUser, userId }) => {
//   const { t } = useTranslation();
//   const loggedInUser = useLoggedInUser();

//   const validatePassword = (value: string) => {
//     return /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value) && value.length >= 8;
//   };
//   return (
//     <div className="user-form">
//       <div className="two-column-flex-form">
//         <div>
//           <FormItem name="firstName" label={t('common.firstName')} style={{ padding: 0 }}>
//             <Input viewMode={!editMode} className="with-background" />
//           </FormItem>
//         </div>
//         <div>
//           <FormItem name="lastName" label={t('common.lastName')}>
//             <Input viewMode={!editMode} className="with-background" />
//           </FormItem>
//         </div>
//       </div>

//       <FormItem
//         name="email"
//         label={t('common.email')}
//         rules={[{ required: true }, { type: 'email' }]}
//       >
//         <Input viewMode={!editMode || createUser} className="with-background" />
//       </FormItem>

//       <FormItem
//         name="password"
//         label={t('login.password')}
//         required={createUser}
//         rules={[
//           () => ({
//             validator(_, value) {
//               if (createUser) {
//                 if (value && validatePassword(value)) {
//                   return Promise.resolve();
//                 }
//                 return Promise.reject(new Error(t('login.error.password-policy')));
//               } else {
//                 if (!value || validatePassword(value)) {
//                   return Promise.resolve();
//                 }
//                 return Promise.reject(new Error(t('login.error.password-policy')));
//               }
//             },
//           }),
//         ]}
//       >
//         <Input
//           viewMode={!editMode}
//           type="password"
//           className="with-background"
//           placeholder="*********"
//         />
//       </FormItem>

//       <div className="two-column-flex-form">
//         <div>
//           <FormItem name="dateOfBirth" label={t('common.dateOfBirth')}>
//             <DatePicker
//               viewMode={!editMode}
//               placeholder={editMode ? 'YYYY-MM-DD' : ''}
//               className="with-background"
//             />
//           </FormItem>
//         </div>
//         <div>
//           <FormItem name="language" label={t('common.language')}>
//             <Select viewMode={!editMode} className="with-background">
//               {languages.map((lng) => (
//                 <Option key={lng.value} value={lng.value}>
//                   {lng.name}
//                 </Option>
//               ))}
//             </Select>
//           </FormItem>
//         </div>
//       </div>

//       <div className="two-column-flex-form">
//         <div style={{ paddingLeft: 0 }}>
//           <FormItem name="notifyViaEmail" valuePropName="checked" label={t('users.notifyViaEmail')}>
//             <Switch viewMode={!editMode} />
//           </FormItem>
//         </div>
//         {loggedInUser.globalRoles &&
//           loggedInUser.globalRoles.includes(roleTypes.ADMIN) &&
//           loggedInUser.id !== userId && (
//             <div>
//               <FormItem name="admin" valuePropName="checked" label={t('users.admin')}>
//                 <Switch viewMode={!editMode} />
//               </FormItem>
//             </div>
//           )}
//       </div>
//     </div>
//   );
// };

// export default UserFormItems;
