import { Identitfiable } from '../common/commonTypes';

export interface ValueSet extends Identitfiable {
  code: string;
  label: string;
  description?: string;
  items?: ValueSetItem[];
}

export interface ValueSetItem extends Identitfiable {
  code: string;
  label: string;
  ordering?: number;
  icon?: string;
}

export const ValueSetCode = {
  org: 'ORG_CAPABILITIES',
  personal: 'PERSONAL_CAPABILITIES',
  eventTypes: 'EVENT_TYPES'
};
