import React from 'react';
import OrgTreeSelect from './orgTreeSelect';
import './pageContent.scss';

interface TabBarProps {
  tabs: string[];
  setActiveKey: (value: React.SetStateAction<number>) => void;
  activeKey: number;
  customClass?: string;
  showOrgTree?: boolean;
}

export const TabBar: React.FC<TabBarProps> = ({
  showOrgTree,
  children,
  tabs,
  setActiveKey,
  activeKey,
  customClass,
}) => {
  return (
    <div className={`page-title-container ${customClass}`}>
      {showOrgTree && <OrgTreeSelect />}
      {children}
      <div className="tabs-container">
        {tabs.map((tabName: string, index: number) => {
          return (
            <a
              key={index}
              className={`tab ${activeKey === index ? 'active' : ''}`}
              onClick={() => setActiveKey(index)}
            >
              {tabName}
            </a>
          );
        })}
      </div>
    </div>
  );
};
