import moment from 'moment';
import { CommonDateProperties } from '../common/commonTypes';

const convertDates = (dateKeys: string[], object: any, index?: number) => {
  if (object) {
    dateKeys.forEach((dateKey) => {
      if (object[dateKey]) {
        let date = moment(object[dateKey]);
        if (date.isValid()) {
          object[dateKey] = date;
        } else {
          let idx = index ? `s[${index}]` : '';
          throw new Error(
            `object${idx}.${dateKey} is not a valid date: \n${JSON.stringify(object, null, 2)}`,
          );
        }
      }
    });
  }
};

const convertToString = (dateKeys: string[], object: any, showTime: boolean = true, index?: number) => {
  if (object) {
    dateKeys.forEach((dateKey) => {
      if (object[dateKey]) {
        let date = object[dateKey];
        if (!showTime) {
          date = object[dateKey].format('YYYY-MM-DD');
        }

        object[dateKey] = date;
      }
    });
  }
};

export const convertStringToDate = (object: any, dateKeys?: string[]) => {
  if (Array.isArray(object)) {
    object.forEach((item, index) => {
      if (item) {
        dateKeys && convertDates(dateKeys, item, index);
        convertDates(CommonDateProperties, item, index);
      }
    });
  } else {
    dateKeys && convertDates(dateKeys, object);
    convertDates(CommonDateProperties, object);
  }
};

export const convertDateToString = (object: any, dateKeys?: string[], showTime?: boolean) => {
  if (Array.isArray(object)) {
    object.forEach((item, index) => {
      if (item) {
        dateKeys && convertToString(dateKeys, item, showTime, index);
        convertToString(CommonDateProperties, item, showTime, index);
      }
    });
  } else {
    dateKeys && convertToString(dateKeys, object, showTime);
    convertToString(CommonDateProperties, object, showTime);
  }
};
