import { useSelector } from "react-redux";
import { RootState } from "../common/commonTypes";

export const useSelectedOrgId = () =>
  useSelector((state: RootState) => {
    return state.organizations.selectedOrgId;
});

export const useExpandedKeys = () =>
  useSelector((state: RootState) => {
    return state.organizations.expandedKeys;
});

export const useOrganizations = () =>
  useSelector((state: RootState) => {
    return state.organizations;
});

export const useSelectedOrganization = () => {
  const selectedOrgId = useSelector((state: RootState) => {
    return state.organizations.selectedOrgId;
  });

  const orgenizations = useOrganizations();

  return orgenizations.allIds.find((orgId: number) => orgId === selectedOrgId)

}