import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { logOut } from '../auth/authActions';
import {
  ApartmentOutlined,
  CalendarOutlined,
  CaretDownOutlined,
  CloseOutlined,
  MenuOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import useComponentVisible from '../utils/useComponentVisible';
import './navStyle.scss';
import { roleTypes } from '../users/userType';
import { RoleChecker } from '../utils/roleChecker';
import { languages } from '../common/commonTypes';
import { OrganizationDeatils } from '../organizations/orgTypes';
import { FormInstance } from 'antd';
import { createOrganization } from '../organizations/orgActions';
import { handleErrors } from '../utils/validation';
import OrgActions from '../organizations/components/details/orgActions';
import { successMessage } from '../common/components/ant/messages';
import { getValueSets } from '../valueSets/valueSetActions';

const Navigation: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [languageRef, isLanguageDropdownVisible, setIsLanguageDropdownVisible] =
    useComponentVisible(false);
  const [userRef, isUserDropdownVisible, setIsUserDropdownVisible] = useComponentVisible(false);
  const [settingsRef, isSettingsMenuVisible, setIsSettingsMenuVisible] = useComponentVisible(false);
  const [isMenuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const selectedLanguage = i18n.language ? i18n.language : 'hu';

  const handleCancel = () => {
    setShowModal(false);
  };
  const handleSubmit = (values: OrganizationDeatils, form: FormInstance) => {
    createOrganization(values)
      .then(() => {
        successMessage(t('common.message.addSuccess'));
        handleCancel();
      })
      .catch((error) => {
        handleErrors(error.response.data, t, form, handleCancel);
      });
  };

  return (
    <>
      <OrgActions
        showModal={showModal}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        title={t('organizations.actions.newRootOrgTitle')}
      />
      <nav className={`main-nav ${isMenuDrawerOpen ? '' : 'closed'}`}>
        <div className="logo-container">
          <Link to="/" className="brand-logo">
            <img className="logo-image" src="/static/logo.png" alt="event-manager" />
            <span className="logo-title">Event Manager</span>
          </Link>
          <div className="toggle-menu">
            {!isMenuDrawerOpen && (
              <MenuOutlined
                onClick={() => {
                  setMenuDrawerOpen(true);
                }}
              />
            )}
            {isMenuDrawerOpen && (
              <CloseOutlined
                onClick={() => {
                  setMenuDrawerOpen(false);
                }}
              />
            )}
          </div>
        </div>
        <div className="navbar">
          <NavLink className="main-nav-link" key="events" to="/events">
            <CalendarOutlined className="nav-icon" />
            <span className="label">{t('events.title')}</span>
          </NavLink>
          <NavLink className="main-nav-link" key="organizations" to="/organizations">
            <ApartmentOutlined className="nav-icon" />
            <span className="label">{t('organizations.title')}</span>
          </NavLink>
          <NavLink className="main-nav-link" key="users" to="/users">
            <UsergroupAddOutlined className="nav-icon" />
            <span className="label">{t('users.users')}</span>
          </NavLink>
        </div>
        <div className="navbar-links">
          <li
            ref={languageRef}
            className="dropdown-menu-header"
            onClick={() => setIsLanguageDropdownVisible(!isLanguageDropdownVisible)}
          >
            <span className="selected-language">{selectedLanguage}</span>
            <CaretDownOutlined
              className={`arrow-icon ${isLanguageDropdownVisible ? 'active' : ''}`}
            />
            <ul
              className={`dropdown-menu-content-wrapper ${
                isLanguageDropdownVisible ? 'active' : ''
              }`}
            >
              {languages.map((language) => {
                if (language.value !== selectedLanguage) {
                  return (
                    <li key={language.alias}>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          i18n.changeLanguage(language.value);
                          getValueSets();
                        }}
                      >
                        {language.value}
                      </div>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </li>

          <li
            ref={userRef}
            className="dropdown-menu-header"
            onClick={() => setIsUserDropdownVisible(!isUserDropdownVisible)}
          >
            <UserOutlined className="user-icon" />
            <ul
              className={`dropdown-menu-content-wrapper ${isUserDropdownVisible ? 'active' : ''}`}
            >
              <li>
                <div id="profile-container"></div>
              </li>
              <li>
                <div className="dropdown-item" onClick={() => logOut()}>
                  {t('login.logout')}
                </div>
              </li>
            </ul>
          </li>

          <RoleChecker roles={[roleTypes.ADMIN]}>
            <li
              ref={settingsRef}
              className="dropdown-menu-header"
              onClick={() => setIsSettingsMenuVisible(!isSettingsMenuVisible)}
            >
              <SettingOutlined className="user-icon" />
              <ul
                className={`dropdown-menu-content-wrapper ${isSettingsMenuVisible ? 'active' : ''}`}
              >
                <li>
                  {/* <div className="dropdown-item" id="create-root-container"></div> */}
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t('organizations.actions.newRootOrg')}
                  </div>
                </li>
              </ul>
            </li>
          </RoleChecker>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
