import { CapabilityOfferStats } from '../capabilities/capabilityType';
import { NormalizedState } from '../common/commonTypes';
import { eventActions, EventActionTypes } from './eventActionTypes';
import { CapabilityOfferState } from './eventType';

export function eventCapabilities(
  state: NormalizedState<CapabilityOfferStats> = { byId: {}, allIds: [] },
  action: eventActions,
) {
  switch (action.type) {
    case EventActionTypes.SET_CAPABILITIES:
      return action.payload;

    case EventActionTypes.UPDATE_MY_OFFERS:
      const prevState = { ...state.byId[action.payload.typeId] };
      const prevOfferPcs = prevState.offer ? prevState.offer.pcs : 0;
      const diff = action.payload.pcs - prevOfferPcs;

      return {
        byId: {
          ...state.byId,
          [action.payload.typeId]: {
            ...state.byId[action.payload.typeId],
            offer: action.payload,
            totalAccepted: (prevState.totalAccepted ? prevState.totalAccepted : 0) + diff,
            totalOffered: (prevState.totalOffered ? prevState.totalOffered : 0) + diff,
          },
        },
        allIds: [...state.allIds],
      };

    case EventActionTypes.UPDATE_OFFER_STATE:
      let newById = { ...state.byId };

      action.payload.map((offer) => {
        const prevState = state.byId[offer.typeId].offeredByOthers!.find(
          (el) => el.id === offer.id,
        );
        let totalAccepted = state.byId[offer.typeId].totalAccepted;
        let totalDeclined = state.byId[offer.typeId].totalDeclined;
        const pcs = offer.pcs;

        if (offer.state === CapabilityOfferState.ACCEPTED) {
          totalAccepted = (totalAccepted ? totalAccepted : 0) + pcs;
          if (prevState!.state === CapabilityOfferState.DECLINED) {
            totalDeclined = (totalDeclined ? totalDeclined : 0) - pcs;
          }
        }

        if (offer.state === CapabilityOfferState.DECLINED) {
          totalDeclined = (totalDeclined ? totalDeclined : 0) + pcs;
          if (prevState!.state === CapabilityOfferState.ACCEPTED) {
            totalAccepted = (totalAccepted ? totalAccepted : 0) - pcs;
          }
        }

        newById = {
          ...newById,
          [offer.typeId]: {
            ...state.byId[offer.typeId],
            totalAccepted: totalAccepted,
            totalDeclined: totalDeclined,
            offeredByOthers: state.byId[offer.typeId].offeredByOthers!.map((el) => {
              if (el.id === offer.id) {
                return offer;
              } else {
                return el;
              }
            }),
          },
        };
      });

      return {
        byId: {
          ...newById,
        },
        allIds: [...state.allIds],
      };

    default:
      return state;
  }
}
