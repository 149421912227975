import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { useValueSetItems } from '../../valueSets/valueSetState';
import { ValueSetCode, ValueSetItem } from '../../valueSets/valueSetType';
import { EventSummary } from '../eventType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Tag from '../../common/components/ant/tag';
import { useOrganizations, useSelectedOrgId } from '../../organizations/orgState';
import moment from 'moment';
import Space from '../../common/components/ant/space';
import { Button, InfoButton } from '../../common/components/ant/button';
import { useHistory } from 'react-router-dom';
import Table from '../../common/components/ant/table';
import { OrganizationDeatils } from '../../organizations/orgTypes';
import { Membership, roleTypes } from '../../users/userType';
import { useLoggedInUser } from '../../auth/authState';

interface EventListProps {
  events: EventSummary[];
  setChangeOrganizerParameters?: (event: EventSummary, orgIds: number[]) => void;
}
const EventList: React.FC<EventListProps> = ({ events, setChangeOrganizerParameters }) => {
  const valueSetItems = useValueSetItems(ValueSetCode.eventTypes);
  const organizations = useOrganizations();
  const selectedOrgId = useSelectedOrgId();

  const loggedInUser = useLoggedInUser();

  const history = useHistory();
  const { t } = useTranslation();

  const checkParents = (event: EventSummary) => {
    let parentOrgIds: { orgId: number; isOrganizer: boolean }[] = [];
    const organizer = organizations.byId[event.organizerOrgId];
    if (organizer && organizer.parentId) {
      const parentOrganization = organizations.byId[organizer.parentId];
      if (parentOrganization) {
        const foundedElements = findParents(parentOrganization, parentOrgIds);

        let idx: number | undefined;

        foundedElements.forEach((el, index) => {
          if (el.isOrganizer) {
            idx = index;
          }
        });

        if (idx !== undefined) {
          return foundedElements.filter((el, index) => index <= idx!).map((el) => el.orgId);
        }
      }
    }
    return [];
  };

  const findParents = (
    org: OrganizationDeatils,
    parentOrgIds: { orgId: number; isOrganizer: boolean }[],
  ) => {
    if (loggedInUser.accounts) {
      const account = loggedInUser.accounts.find((acc: Membership) => acc.orgId === org.id);

      parentOrgIds.push({
        orgId: org.id,
        isOrganizer:
          (account && account.roles.includes(roleTypes.ORGANIZER)) ||
          loggedInUser.globalRoles.includes(roleTypes.ADMIN)
            ? true
            : false,
      });

      if (org.parentId && organizations.byId[org.parentId]) {
        findParents(organizations.byId[org.parentId], parentOrgIds);
      }
    }
    return parentOrgIds;
  };

  const changeOrganizerAction = (event: EventSummary) => {
    if (setChangeOrganizerParameters) {
      const orgIds = checkParents(event);

      if (orgIds && orgIds.length) {
        return (
          <Button
            onClick={() => {
              setChangeOrganizerParameters(event, orgIds);
            }}
          >
            {t('events.changeOrgIdToMe')}
          </Button>
        );
      }
    }
  };

  const columns = [
    {
      key: 'label',
      render: (record: EventSummary) => {
        const valueSetItem = valueSetItems
          ? valueSetItems.find((valueSet: ValueSetItem) => valueSet.id === record.typeId)
          : null;
        return (
          <>
            {valueSetItem && (
              <FontAwesomeIcon
                icon={valueSetItem.icon! as IconName}
                className={`event-type-icon ${valueSetItem.code.toLowerCase()}`}
              />
            )}
          </>
        );
      },
    },
    {
      title: t('events.summary'),
      dataIndex: 'summary',
      key: 'summary',
    },
    {
      title: t('common.state'),
      key: 'state',
      render: (record: EventSummary) => {
        return <Tag className="custom-tag blue">{t(`events.state.${record.state}`)}</Tag>;
      },
    },
    {
      title: t('events.organizer'),
      key: 'organizer',
      render: (record: EventSummary) => {
        return <>{record.organizerOrgName} </>;
      },
    },

    {
      title: t('common.start'),
      key: 'validFrom',
      render: (record: EventSummary) => {
        if (record.plannedStart) {
          return <>{moment(record.plannedStart).format('YYYY-MM-DD HH:mm')} </>;
        }
      },
    },
    {
      title: t('common.end'),
      key: 'plannedEnd',
      render: (record: EventSummary) => {
        if (record.plannedEnd) {
          return <>{moment(record.plannedEnd).format('YYYY-MM-DD HH:mm')} </>;
        }
      },
    },
    {
      title: t('common.actions'),
      key: 'action',
      render: (record: EventSummary) => (
        <Space size="middle">
          <InfoButton
            onClick={() => {
              history.push(`events/${record.id}/org/${selectedOrgId}`);
            }}
            text={t('common.more')}
          />

          {changeOrganizerAction(record)}
        </Space>
      ),
    },
  ];
  return (
    <>
      {events && (
        <Table
          columns={columns}
          rowKey={(record) => (record ? record.id : '')}
          dataSource={events}
          pagination={false}
        />
      )}
    </>
  );
};

export default EventList;
