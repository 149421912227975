import { FormInstance } from 'antd';
import { useEffect, useRef } from 'react';

// reset form fields when modal is form, closed
export const useResetFormOnCloseModal = ({
  form,
  showModal: visible,
}: {
  form: FormInstance;
  showModal: boolean;
}) => {
  const prevVisibleRef = useRef<boolean>();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;

  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  }, [visible]);
};
