import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button as AntButton, ButtonProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IconButtonWithTextProps extends ButtonProps {
  text?: string;
}

interface ButtonByActionTypeProps extends ButtonProps {
  action: string;
}

export const Button = (props: ButtonProps) => {
  return <AntButton {...props} size="large" className={`${props.className} custom-button`} />;
};

export const ButtonByActionType: React.FC<ButtonByActionTypeProps> = ({ action, ...props }) => {
  return (
    <Button
      {...props}
      icon={action === 'approved' || action === 'accepted' ? <CheckOutlined /> : <CloseOutlined />}
      className={action}
    />
  );
};

export const TestButton: React.FC<IconButtonWithTextProps> = ({ text, ...props }) => {
  return <Button {...props} icon={<CheckOutlined />} className={`save`}></Button>;
};

export const CreateButton: React.FC<IconButtonWithTextProps> = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button
      {...props}
      icon={<PlusOutlined />}
      className={`${props.className} custom-button create`}
    >
      {text ? text : t('common.create')}
    </Button>
  );
};

export const SaveButton: React.FC<IconButtonWithTextProps> = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<CheckOutlined />} className={`${props.className} custom-button save`}>
      {text ? text : t('common.save')}
    </Button>
  );
};

export const CancelButton: React.FC<IconButtonWithTextProps> = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button {...props} className={`${props.className} custom-button cancel`}>
      {text ? text : t('common.cancel')}
    </Button>
  );
};

export const ResetButton: React.FC<IconButtonWithTextProps> = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button
      {...props}
      icon={<CloseOutlined />}
      className={`${props.className} custom-button reset`}
    >
      {text ? text : t('common.reset')}
    </Button>
  );
};

export const EditButton: React.FC<IconButtonWithTextProps> = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button {...props} icon={<EditOutlined />} className={`${props.className} custom-button edit`}>
      {text ? text : t('common.edit')}
    </Button>
  );
};

export const DeleteButton: React.FC<IconButtonWithTextProps> = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button
      {...props}
      icon={<DeleteOutlined />}
      className={`${props.className} custom-button delete`}
    >
      {text ? text : t('common.delete')}
    </Button>
  );
};

export const InfoButton: React.FC<IconButtonWithTextProps> = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button
      {...props}
      icon={<InfoCircleOutlined />}
      className={`${props.className} custom-button info`}
    >
      {text ? text : t('common.info')}
    </Button>
  );
};
