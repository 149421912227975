import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { InputNumber } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CapabilityOffer, CapabilityOfferStats } from '../../capabilities/capabilityType';
import { NormalizedState } from '../../common/commonTypes';
import { ButtonByActionType } from '../../common/components/ant/button';
import Tooltip from '../../common/components/ant/tooltip';
import { useOrganizations, useSelectedOrgId } from '../../organizations/orgState';
import { useValueSetItems } from '../../valueSets/valueSetState';
import { ValueSetCode, ValueSetItem } from '../../valueSets/valueSetType';
import {
  capabilityOffer,
  capabilityOfferActions,
  deleteOffer,
  getCapabilities,
} from '../eventActions';
import { useActiveEvents, useEventCapabilities, useEventById } from '../eventState';
import { CapabilityOfferState, EventSummary } from '../eventType';

export interface IconByStateProps {
  [key: string]: any;
}
export const IconByState: IconByStateProps = {
  DECLINED: <CloseCircleOutlined className="declined" />,
  ACCEPTED: <CheckCircleOutlined className="accepted" />,
  REJECTED: <CloseCircleOutlined className="declined" />,
  APPROVED: <CheckCircleOutlined className="accepted" />,
  OFFERED: <QuestionCircleOutlined className="offered" />,
};

const Capabilities: React.FC = () => {
  const activeEvents = useActiveEvents();
  const { id, orgId } = useParams<{ id?: string; orgId?: string }>();
  const eventProps = useEventById(id ? parseInt(id) : 0);
  const event = eventProps.event;
  const eventIsActive = eventProps.isActive;
  const capabilities = useEventCapabilities();
  const organizations = useOrganizations();
  const valueSetItems = useValueSetItems(ValueSetCode.org);
  const selectedOrgId = useSelectedOrgId();

  const { t } = useTranslation();
  let pollingTimer: any;

  useEffect(() => {
    poll();

    return () => {
      clearTimeout(pollingTimer);
    };
  }, []);

  const poll = () => {
    clearTimeout(pollingTimer);

    if (orgId && id) {
      getCapabilities(parseInt(orgId), parseInt(id));
    }

    pollingTimer = setTimeout(poll, 5000);
  };

  const getCapabilityByTypeId = (typeId?: number) => {
    const valueSetItem = valueSetItems
      ? valueSetItems.find((valueSet: ValueSetItem) => valueSet.id === typeId)
      : null;
    return <>{valueSetItem && valueSetItem.label}</>;
  };

  const changeMyOffer = (value: number, typeId: number, capabilityId?: number) => {
    const json = {
      orgId: parseInt(orgId!),
      pcs: value,
      typeId: typeId,
      state: CapabilityOfferState.OFFERED,
    };
    if ((value === 0 || !value) && capabilityId) {
      deleteOffer(parseInt(id!), capabilityId, json);
    } else {
      capabilityOffer(parseInt(id!), json);
    }
  };

  const isDisabledOfferField = (capability: CapabilityOfferStats) => {
    return (
      (capability.offer && capability.offer.state === CapabilityOfferState.DECLINED) ||
      !eventIsActive ||
      (event && !event.openForRegistration)
    );
  };

  const showInputNumberField = (capability: CapabilityOfferStats) => {
    if (capability.validPcs !== undefined) {
      const calculatedOffers = gatMaxOfferPcs(capability);
      return (
        <>
          <InputNumber
            max={calculatedOffers.remaining}
            disabled={isDisabledOfferField(capability)}
            min={0}
            defaultValue={capability.offer ? capability.offer.pcs : 0}
            onChange={(value: number) => {
              if (calculatedOffers.showError) {
                value = 0;
              }
              changeMyOffer(value, capability.typeId, capability.offer ? capability.offer.id : 0);
            }}
            value={capability.offer ? capability.offer.pcs : 0}
          />

          <Tooltip title={CapabilityTooltip({ capabilityOfferStats: capability, activeEvents, t })}>
            <InfoCircleOutlined
              className={`capability-icon show ${calculatedOffers.showError ? 'error' : ''}`}
            />
          </Tooltip>
          <Tooltip title={t('capabilities.maybeNotValid')}>
            <WarningFilled
              className={`capability-icon ${calculatedOffers.showWarning ? 'show' : ''} warning`}
            />
          </Tooltip>
        </>
      );
    }
  };

  const gatMaxOfferPcs = (capability: CapabilityOfferStats) => {
    let showWarning = false;
    let showError = false;
    if (
      capability.offer &&
      capability.offer.state !== CapabilityOfferState.DECLINED &&
      capability.offer.pcs + capability.offeredToOtherEventsPcs > capability.totalPcs
    ) {
      showError = true;
    }
    if (capability.validPcs === 0 || capability.totalPcs === 0) {
      return {
        remaining: 0,
        showWarning: false,
        showError: showError,
      };
    }
    const restPcs =
      capability.totalPcs -
      capability.offeredToOtherEventsPcs -
      (capability.offer ? capability.offer.pcs : 0);
    const restValidPcs =
      capability.validPcs -
      capability.offeredToOtherEventsPcs -
      (capability.offer ? capability.offer.pcs : 0);
    let remaining = restValidPcs > 0 ? restValidPcs : 0;
    if (restPcs > restValidPcs && capability.offeredToOtherEventsPcs) {
      remaining = Math.max(restPcs, restValidPcs);

      if (
        capability.offeredToOtherEventsPcs + (capability.offer ? capability.offer.pcs : 0) >
        capability.validPcs
      ) {
        showWarning = true;
      }
    }

    return {
      remaining: capability.offer ? capability.offer.pcs + remaining : remaining,
      showWarning: showWarning,
      showError: showError,
    };
  };

  const showCell = () => {
    return event && eventIsActive && event.organizerOrgId === parseInt(orgId!);
  };

  return (
    <>
      <div className="table-collapse">
        <div className="table-row table-row-header">
          <div className="table-cell capability-cell column-header"></div>
          <div className="table-cell my-offers-cell column-header">
            <span style={{ margin: 0, whiteSpace: 'nowrap' }}>
              {organizations.byId[selectedOrgId].name + ' ' + t('capabilities.myOffers')}
            </span>
          </div>
          <div className="table-cell all-offers-cell column-header">
            {t('capabilities.allOffers')}
          </div>
          {showCell() ? (
            <>
              {' '}
              <div className="table-cell accepted-cell column-header">
                {t('capabilities.accepted')}
              </div>
              <div className="table-cell rejected-cell column-header">
                {t('capabilities.rejected')}
              </div>
            </>
          ) : (
            <>
              <div className="table-cell state-cell column-header">{t('capabilities.state')}</div>
            </>
          )}
        </div>

        {event &&
          capabilities &&
          capabilities.allIds.map((capabilityId: number) => {
            const capability = capabilities.byId[capabilityId];
            return (
              <div key={capabilityId} style={{ width: '100%' }}>
                <div className="table-row" key={capabilityId}>
                  <div className="table-cell capability-cell" style={{ fontWeight: 600 }}>
                    <div className="table-cell-content">
                      {getCapabilityByTypeId(capability.typeId)}
                    </div>
                  </div>
                  <div className="table-cell my-offers-cell">
                    <div className="table-cell-content">{showInputNumberField(capability)}</div>
                  </div>
                  <div className="table-cell all-offers-cell">
                    <div className="table-cell-content">{capability.totalOffered}</div>
                  </div>

                  {showCell() ? (
                    <>
                      <div className="table-cell accepted-cell">
                        <div className="table-cell-content">{capability.totalAccepted}</div>
                      </div>
                      <div className="table-cell rejected-cell">
                        <div className="table-cell-content">{capability.totalDeclined}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="table-cell state-cell">
                        <div className="table-cell-content">
                          {capability.offer && IconByState[capability.offer.state]}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {showCell() &&
                  capability.offeredByOthers &&
                  capability.offeredByOthers.map((offeredByOthers: CapabilityOffer) => {
                    return (
                      <div className="table-row" key={offeredByOthers.id}>
                        <div className="table-cell capability-cell">
                          <div className="table-cell-content" style={{ paddingLeft: '30px' }}>
                            {organizations.byId[offeredByOthers.orgId].name}
                          </div>
                        </div>
                        <div className="table-cell my-offers-cell"></div>
                        <div className="table-cell all-offers-cell">
                          <div className="table-cell-content">{offeredByOthers.pcs}</div>
                        </div>

                        <div className="table-cell accepted-cell">
                          <div className="table-cell-content">
                            <ButtonByActionType
                              action={CapabilityOfferState.ACCEPTED.toLowerCase()}
                              disabled={offeredByOthers.state === CapabilityOfferState.ACCEPTED}
                              onClick={() => {
                                capabilityOfferActions(parseInt(id!), offeredByOthers.id, {
                                  state: CapabilityOfferState.ACCEPTED,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="table-cell rejected-cell">
                          <div className="table-cell-content">
                            <ButtonByActionType
                              action={CapabilityOfferState.DECLINED.toLowerCase()}
                              disabled={offeredByOthers.state === CapabilityOfferState.DECLINED}
                              onClick={() => {
                                capabilityOfferActions(parseInt(id!), offeredByOthers.id, {
                                  state: CapabilityOfferState.DECLINED,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
    </>
  );
};

interface CapabilityTooltipProps {
  capabilityOfferStats: CapabilityOfferStats;
  activeEvents: NormalizedState<EventSummary>;
  t: any;
}
const CapabilityTooltip: React.FC<CapabilityTooltipProps> = ({
  capabilityOfferStats,
  activeEvents,
  t,
}) => {
  return (
    <div className="capability-offer-tooltip-container">
      {capabilityOfferStats.offeredToOtherEvents && (
        <>
          <p className="title">{t('capabilities.offeredToOtherEvents')}:</p>

          <ul className="list">
            {capabilityOfferStats.offeredToOtherEvents.map((offer) => {
              if (offer.eventId) {
                const event = activeEvents.byId[offer.eventId];
                if (event) {
                  return (
                    <li key={offer.id} className="list-item">
                      <span className="summary">{event.summary}:</span>
                      <span>
                        {offer.pcs}
                        {t('capabilities.pcs')}
                      </span>
                    </li>
                  );
                }
              }
            })}
          </ul>
        </>
      )}

      <p className="total">
        {t('capabilities.totalPcs')}: {capabilityOfferStats.totalPcs}
      </p>
      <p className="total">
        {t('capabilities.validPcs')}: {capabilityOfferStats.validPcs}
      </p>
    </div>
  );
};

export default Capabilities;
