import { Action } from 'redux';
import { ValueSet } from './valueSetType';

export enum ValueSetActionTypes {
  SET_VALUE_SETS = 'SET_VALUE_SETS',
}

export interface SetValueSetsAction extends Action {
  type: ValueSetActionTypes.SET_VALUE_SETS;
  payload: ValueSet[];
}

export type ValueSetActions = SetValueSetsAction;
