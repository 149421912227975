import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActiveEvents from './activeEvents';
import { TabBar } from '../../common/components/pageContent/tabBar';
import { ComponentType } from '../../common/commonTypes';
import { ContentWrapper } from '../../common/components/pageContent/contentWrapper';
import EventHistory from './eventHistory';
//import EventRegistration from './registrationHelper/eventRegistration';
//import MyEvents from './registrationHelper/myEvents';

import './eventStyle.scss';
import { LayoutWrapper } from '../../common/components/pageContent/layoutWrapper';

const Events: React.FC = () => {
  const [activeKey, setActiveKey] = useState(0);

  const { t } = useTranslation();
  const tabs = [t('events.tabs.active'), t('events.tabs.history')];
  const components: ComponentType = {
    0: <ActiveEvents />,
    1: <EventHistory />,
    //    2: <EventRegistration />,
    //    3: <MyEvents />,
  };

  return (
    <LayoutWrapper>
      <TabBar
        showOrgTree={true}
        tabs={tabs}
        setActiveKey={(index) => setActiveKey(index)}
        activeKey={activeKey}
      />
      <ContentWrapper>{components[activeKey]}</ContentWrapper>
    </LayoutWrapper>
  );
};

export default Events;
