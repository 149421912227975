import { Capability } from '../capabilities/capabilityType';
import { Auditable, Identitfiable } from '../common/commonTypes';

export interface User extends Identitfiable {
  email: string;
  oldPassword?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: moment.Moment;
  globalRoles: string[];
  active?: boolean;
  primaryOrgId?: number;
  primaryOrgLocked?: boolean;
  accounts?: Membership[];
  capabilities: Capability[];
  language?: string;
  admin?: boolean;
}

export interface Membership extends Identitfiable {
  alias: string; // callsign
  userId?: number; // ref to user (preferred)
  userEmail?: string; // ref to user (alternative)
  orgId?: number; // ref to organization (preferred)
  orgCode?: string; // ref to organization (alternative)
  roles: string[]; // "admin" | "organizer" | "user"
  primary?: boolean; // true for the user's first account, false/not set for all others - primary account can be changed if primaryUnlocked is set to true
  active?: boolean;
}

export interface AccountDetails extends Membership, Auditable {}

export const initialUser: User = {
  id: 0,
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  dateOfBirth: undefined,
  globalRoles: [],
  accounts: [],
  capabilities: [],
};

export const initialAccount = {
  alias: '',
  roles: [],
};

export enum roleTypes {
  ADMIN = "admin",
  ORGANIZER = "organizer",
  USER = "user"
}

export const roles = [roleTypes.ADMIN, roleTypes.ORGANIZER, roleTypes.USER]
export const UserDateProperties: string[] = ['dateOfBirth'];