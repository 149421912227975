import { orgActions, OrgActionTypes } from './orgActionTypes';
import { OrganizationState } from './orgTypes';

const initialState = {
  byId: {},
  allIds: [],
  selectedOrgId: 0,
  expandedKeys: []
};

export function organizations(
  state: OrganizationState = initialState,
  action: orgActions,
) {
  switch (action.type) {
    case OrgActionTypes.SET_SELECTED_ORG:
      return {
        ...state,
        selectedOrgId: action.payload,
      };

    case OrgActionTypes.SET_EXPANDED_KEYS:
      return {
        ...state,
        expandedKeys: action.payload,
      };

    case OrgActionTypes.SET_ORGANIZATIONS:
      return {
        ...state,
        byId: { ...state.byId, ...action.payload.byId },
        allIds: [
          ...state.allIds,
          ...action.payload.allIds.filter((id: number) => !state.allIds.includes(id)),
        ],
        selectedOrgId: state.selectedOrgId
      };

    case OrgActionTypes.UPDATE_ORGANIZATIONS:
      const id = action.payload.id;
      if (state.allIds.find((orgId) => orgId === id)) {
        return {
          ...state,
          byId: { ...state.byId, [id]: { ...state.byId[id], ...action.payload } },
          allIds: [...state.allIds],
        };
      }
      return {
        ...state,
        byId: { ...state.byId, [id]: action.payload },
        allIds: [...state.allIds, id],
      };

    case OrgActionTypes.ADD_NEW_CHILD:
      const parentId = action.payload.parentId!;
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {...action.payload, children: []},
          [parentId]: {
            ...state.byId[parentId],
            children: [...state.byId[parentId].children, action.payload.id],
          },
        },
        allIds: [...state.allIds, action.payload.id],
        expandedKeys: [...state.expandedKeys, parentId]
      };

    default:
      return state;
  }
}
