import { CapabilityOfferStats } from '../capabilities/capabilityType';
import { Organization } from '../organizations/orgTypes';

interface normalizeListType {
  id: number;
}

export const normalize = <T extends normalizeListType>(list: T[]) => {
  let allIds: number[] = [];

  const normalizedObjects = list.reduce((acc: { [x: number]: T }, item: T) => {
    acc[item.id] = item;
    allIds.push(item.id);
    return acc;
  }, {});

  const normalizedList = {
    byId: normalizedObjects,
    allIds: allIds,
  };
  return normalizedList;
};

export const normalizeOffers = (list: CapabilityOfferStats[]) => {
  let allIds: number[] = [];

  const normalizedObjects = list.reduce(
    (acc: { [x: number]: CapabilityOfferStats }, item: CapabilityOfferStats) => {
      acc[item.typeId] = item;
      allIds.push(item.typeId);
      return acc;
    },
    {},
  );

  const normalizedList = {
    byId: normalizedObjects,
    allIds: allIds,
  };
  return normalizedList;
};

let allIds: number[] = [];
let byId = {};

export const clearOrgs = () => {
  allIds = [];
  byId = {};
}
export const normalizeOrgTree = (organizations: Organization[]) => {
  organizations.forEach((org) => {
    const children = org.childOrganizations;
    const { childOrganizations, ...withoutChildren } = org;
    withoutChildren.children = org.childOrganizations ? org.childOrganizations.map(org => org.id) : []
    byId = { ...byId, [org.id]: { ...withoutChildren } };
    if(!allIds.includes(org.id)) {
      allIds.push(org.id);
    }


    if (children) {
      normalizeOrgTree(children);
    }
  });

  return {
    byId: byId,
    allIds: allIds,
  };
};

export const normalizeObjByOrgId = (list: any[]) => {
  let element: { [x: number]: any } = {};

  list.forEach((el: any) => {
    element = {
      ...element,
      [el.orgId]: element[el.orgId] ? element[el.orgId].concat(el) : [el],
    };
  });

  return element;
};
