import { store } from '../common/rootReducer';
import { normalize, normalizeOffers, normalizeObjByOrgId } from '../utils/normalizr';
import { EventActionTypes } from './eventActionTypes';
import * as eventService from './eventService';
import { EventSummary, RegistrationState } from './eventType';

export const getActiveEvents = (organizationId: number) => {
  return eventService.getActiveEventsByOrgId(organizationId).then((response) => {
    store.dispatch({
      type: EventActionTypes.SET_ACTIVE_EVENTS,
      payload: normalize(response),
    });

    return response;
  });
};

export const getClosedEvents = (organizationId: number, from: any, to: any) => {
  return eventService.getClosedEventsByOrgId(organizationId, from, to).then((response) => {
    store.dispatch({
      type: EventActionTypes.SET_CLOSED_EVENTS,
      payload: normalize(response),
    });
  });
};

export const addEvent = (event: EventSummary) => {
  return eventService.createEvent(event).then((response) => {
    store.dispatch({
      type: EventActionTypes.ADD_EVENT,
      payload: response,
    });

    return response;
  });
};

export const updateEvent = (event: EventSummary) => {
  return eventService.updateEvent(event).then((response) => {
    store.dispatch({
      type: EventActionTypes.UPDATE_EVENT,
      payload: response,
    });

    return response;
  });
};

export const getEventDetails = (eventId: number) => {
  return eventService.getEvent(eventId).then((response) => {
    store.dispatch({
      type: EventActionTypes.UPDATE_EVENT,
      payload: response,
    });
  });
}

export const getRegistrations = (orgId: number, eventId: number) => {
  return eventService.getRegistrations(orgId, eventId).then((response) => {
    store.dispatch({
      type: EventActionTypes.SET_REGISTRATIONS,
      orgId: orgId,
      eventId: eventId,
      payload: normalizeObjByOrgId(response),
    });
  });
}

export const getCapabilities = (orgId: number, eventId: number) => {
  return eventService.getCapabilities(orgId, eventId).then((response) => {
    store.dispatch({
      type: EventActionTypes.SET_CAPABILITIES,
      payload: normalizeOffers(response),
    });
  });
}

export const registrationStatusUpdate = (registrationId: string, eventId: number, orgId: number, state: RegistrationState) => {
  return eventService.registrationStatusUpdate(registrationId, eventId, state).then((response) => {
    store.dispatch({
      type: EventActionTypes.UPDATE_REGISTRATION_STATUS,
      payload: response,
      eventId: eventId,
      orgId: orgId
    })
  })
}

export const capabilityOffer = (eventId: number, capability: any) => {
  return eventService.capabilityOffer(capability, eventId).then((response) => {
    store.dispatch({
      type: EventActionTypes.UPDATE_MY_OFFERS,
      payload: response,
    });
  })
}

export const deleteOffer = (eventId: number, capabilityId: number, capability: any) => {
  return eventService.deleteOffer(capabilityId, eventId).then((response) => {
    store.dispatch({
      type: EventActionTypes.UPDATE_MY_OFFERS,
      payload: capability,
    });
  })
}

export const capabilityOfferActions = (eventId: number, offerId: number, state: any) => {
  return eventService.capabilityOfferActions(offerId, eventId, state).then((response) => {
    store.dispatch({
      type: EventActionTypes.UPDATE_OFFER_STATE,
      payload: response,
    });
  })
}