import { useSelector } from 'react-redux';
import { RootState } from '../common/commonTypes';

export const useActiveEvents = () =>
  useSelector((state: RootState) => {
    return state.activeEvents;
  });

export const useClosedEvents = () =>
  useSelector((state: RootState) => {
    return state.closedEvents;
  });

export const useEventById = (id?: number) => {
  const acitveEvents = useActiveEvents();
  const closedEvents = useClosedEvents();

  let eventId = acitveEvents.allIds.find((eventId) => eventId === id);
  if (eventId) {
    return { event: acitveEvents.byId[eventId], isActive: true};
  }

  eventId = closedEvents.allIds.find((eventId) => eventId === id);
  if (eventId) {
    return {event: closedEvents.byId[eventId], isActive: false};
  }

  return {event: undefined, isActive: false}
};

export const useEventRegistrations = () =>
  useSelector((state: RootState) => {
    return state.registrationsByOrg;
  });

export const useEventCapabilities = () =>
  useSelector((state: RootState) => {
    return state.eventCapabilities;
  });

// export const useEventDetails = () =>
// useSelector((state: RootState) => {
//   return state.eventDetails;
// });
