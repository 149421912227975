import axios from 'axios';
import { convertStringToDate } from '../utils/propertyConverter';
import { EventDateProperties, EventSummary } from './eventType';

const restURL = process.env.REACT_APP_API_ENDPOINT;

export function getActiveEventsByOrgId(orgId: number) {
  return axios.get(`${restURL}/organizations/${orgId}/events/active`).then((response) => {
    convertStringToDate(response.data, EventDateProperties);
    return response.data;
  });
}

export function getClosedEventsByOrgId(orgId: number, from: string, to: string) {
  return axios.get(`${restURL}/organizations/${orgId}/events/closed?from=${from}&to=${to}`).then((response) => {
    convertStringToDate(response.data, EventDateProperties);
    return response.data;
  });
}

export function createEvent(event: EventSummary) {
  return axios.post(`${restURL}/events`, event).then((response) => {
    convertStringToDate(response.data, EventDateProperties);
    return response.data;
  });
}

export function updateEvent(event: EventSummary) {
  return axios.put(`${restURL}/events/${event.id}`, event).then((response) => {
    convertStringToDate(response.data, EventDateProperties);
    return response.data;
  });
}

export function getEvent(eventId: number) {
  return axios.get(`${restURL}/events/${eventId}`).then((response) => {
    convertStringToDate(response.data, EventDateProperties);
    return response.data;
  });
}

export function getAvailableEvents() {
  return axios.get(`${restURL}/events/available`).then((response) => {
    convertStringToDate(response.data, EventDateProperties);
    return response.data;
  });
}

export function getMyEvents() {
  return axios.get(`${restURL}/events/myevents`).then((response) => {
    convertStringToDate(response.data, EventDateProperties);
    return response.data;
  });
}

export function participationResponse(reg: any, eventId: number) {
  return axios.post(`${restURL}/events/${eventId}/status`, reg).then((response) => {
    return response.data;
  });
}

export function registrationStatusUpdate(registrationId: string, eventId: number, state: any) {
  return axios.put(`${restURL}/events/${eventId}/registrations/${registrationId}`, state).then((response) => {
    return response.data;
  });
} 


export function getRegistrations(orgId: number, eventId: number) {
  return axios.get(`${restURL}/organizations/${orgId}/events/${eventId}/registrations`).then((response) => {
    return response.data;
  });
}

export function getCapabilities(orgId: number, eventId: number) {
  return axios.get(`${restURL}/organizations/${orgId}/events/${eventId}/capabilities`).then((response) => {
    return response.data;
  });
}

export function capabilityOffer(capability: any, eventId: number) {
  return axios.post(`${restURL}/events/${eventId}/capabilities`, capability).then((response) => {
    return response.data;
  });
}

export function deleteOffer(capabilityId: number, eventId: number) {
  return axios.delete(`${restURL}/events/${eventId}/capabilities/${capabilityId}`).then((response) => {
    return response.data;
  });
}

export function capabilityOfferActions(capabilityId: any, eventId: number, state: any) {
  return axios.put(`${restURL}/events/${eventId}/capabilities/${capabilityId}`, state).then((response) => {
    return response.data;
  });
}