import { CheckboxProps, Switch as AntSwitch } from 'antd';
import { SwitchProps } from 'react-router';
import './antStyle.scss';

interface ISwitchProps extends SwitchProps {
  viewMode?: boolean;
}

const Switch: React.FC<ISwitchProps> = ({ viewMode, ...props }) => {
  return (
    <AntSwitch
      {...props}
      disabled={viewMode}
      className={`custom-checkbox ${viewMode ? 'view-mode' : ''}`}
    />
  );
};
export default Switch;
