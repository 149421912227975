import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { MonthFilter } from '../../common/monthFilter';
import { useSelectedOrgId } from '../../organizations/orgState';
import { getClosedEvents } from '../eventActions';
import { useClosedEvents } from '../eventState';
import EventList from './eventList';

const EventHistory: React.FC = () => {
  const selectedOrgId = useSelectedOrgId();
  const closedEvents = useClosedEvents();

  const [value, setValue] = useState(moment(new Date()));

  useEffect(() => {
    if (selectedOrgId) {
      handleFilterChange(value);
    }
  }, [selectedOrgId, value]);

  const handleFilterChange = (date: Moment) => {
    const from = date.clone().startOf('month').toISOString();
    const to = date.clone().endOf('month').toISOString();
    getClosedEvents(selectedOrgId, from, to);
  };

  function onChange(date: Moment) {
    setValue(date);
  }

  return (
    <div className="table-container">
      <div className="history-filter-container">
        <MonthFilter value={value} onChange={onChange} isHistory />
      </div>
      <EventList events={closedEvents.allIds.map((id) => closedEvents.byId[id])} />
    </div>
  );
};

export default EventHistory;
