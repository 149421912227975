import React from 'react';
import { useTranslation } from 'react-i18next';
import { editAccount, editUser } from '../../../users/userActions';
import { Membership, User } from '../../../users/userType';
import { useSelectedOrgId } from '../../orgState';
import { FormInstance } from 'antd';
import UserList from '../../../users/components/userList';
import { handleErrors } from '../../../utils/validation';

interface MemberListProps {
  users: User[];
}
const MemberList: React.FC<MemberListProps> = ({ users }) => {
  const selectedOrgId = useSelectedOrgId();
  const { t } = useTranslation();

  const handleSubmitUser = (values: any, form: FormInstance<any> | undefined) => {
    if (!values.password) {
      delete values.password;
    }
    editUser(values, selectedOrgId).catch((error) => handleErrors(error.response.data, t, form));
  };

  const handleSubmitAccount = (values: Membership, form: FormInstance<any> | undefined) => {
    return editAccount(values, selectedOrgId)
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        handleErrors(error.response.data, t, form);
        return Promise.reject();
      });
  };

  return (
    <UserList
      users={users}
      handleSubmitUser={handleSubmitUser}
      handleSubmitAccount={handleSubmitAccount}
      isOrgContext={true}
    />
  );
};

export default MemberList;
