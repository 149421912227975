import { NormalizedState } from '../common/commonTypes';
import { eventActions, EventActionTypes } from './eventActionTypes';
import { EventSummary } from './eventType';

const initialState = {
  byId: {},
  allIds: []
};

export function activeEvents(
  state: NormalizedState<EventSummary[]> = initialState,
  action: eventActions,
) {
  switch (action.type) {
    case EventActionTypes.SET_ACTIVE_EVENTS:
      return action.payload;

    case EventActionTypes.ADD_EVENT:
      return {
        byId: {...state.byId, [action.payload.id]: action.payload},
        allIds: state.allIds.concat(action.payload.id)
      }
    
    case EventActionTypes.UPDATE_EVENT:
      return {
        byId: {...state.byId, [action.payload.id]: action.payload},
        allIds: state.allIds
      }

    default:
      return state;
  }
}
