import { Action } from 'redux';
import { NormalizedState } from '../common/commonTypes';
import { Organization, OrganizationState } from './orgTypes';

export enum OrgActionTypes {
  SET_ORGANIZATIONS = 'SET_ORGANIZATIONS',
  SET_EXPANDED_KEYS = 'SET_EXPANDED_KEYS',
  UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS',
  ADD_NEW_CHILD = 'ADD_NEW_CHILD',
  SET_SELECTED_ORG = 'SET_SELECTED_ORG',
}

export interface SetOrganizationsAction extends Action {
  type: OrgActionTypes.SET_ORGANIZATIONS;
  payload: NormalizedState<OrganizationState>;
}

export interface UpdateOrganization extends Action {
  type: OrgActionTypes.UPDATE_ORGANIZATIONS;
  payload: Organization;
  id: number;
}

export interface AddNewChildOrganization extends Action {
  type: OrgActionTypes.ADD_NEW_CHILD;
  payload: Organization;
  id: number;
}

export interface SetSelectedOrgAction extends Action {
  type: OrgActionTypes.SET_SELECTED_ORG;
  payload: number;
}

export interface SetExpandedKeys extends Action {
  type: OrgActionTypes.SET_EXPANDED_KEYS;
  payload: number[];
}

export type orgActions =
  | SetOrganizationsAction
  | UpdateOrganization
  | SetSelectedOrgAction
  | AddNewChildOrganization
  | SetExpandedKeys;
