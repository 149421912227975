import { NormalizedObject } from '../common/commonTypes';
import { eventActions, EventActionTypes } from './eventActionTypes';
import { RegistrationSummary } from './eventType';

const initialState = {};

export function registrationsByOrg(
  state: NormalizedObject<RegistrationSummary[]> = initialState,
  action: eventActions,
) {
  switch (action.type) {
    case EventActionTypes.SET_REGISTRATIONS:
      return action.payload;

    case EventActionTypes.UPDATE_REGISTRATION_STATUS:
      const registrations = state[action.orgId].map((registration) => {
        if (action.payload.some((e) => e.id === registration.id)) {
          const reg = action.payload.find((el) => el.id === registration.id);
          return { ...registration, ...reg };
        } else {
          return registration;
        }
      });
      return {...state, [action.orgId]: registrations };

    default:
      return state;
  }
}
