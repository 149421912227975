import { NormalizedObject } from '../common/commonTypes';
import { ValueSetActions, CapabilityActionTypes } from './capabilityActionTypes';
import { Capability } from './capabilityType';

const initialState = {};

export function capabilitiesByOrgId(
  state: NormalizedObject<Capability[]> = initialState,
  action: ValueSetActions,
) {
  switch (action.type) {
    case CapabilityActionTypes.SET_ORG_CAPABILITES:
      return { [action.id]: action.payload };

    case CapabilityActionTypes.ADD_ORG_CAPABILITY:
      return { [action.id]: state[action.id].concat(action.payload) };

    case CapabilityActionTypes.UPDATE_ORG_CAPABILITY:
      return {
        [action.id]: state[action.id].map((capability: Capability) => {
          if (capability.id === action.payload.id) {
            return action.payload;
          } else {
            return capability;
          }
        }),
      };

    case CapabilityActionTypes.DELETE_ORG_CAPABILITY:
      return {
        [action.orgId]: state[action.orgId].filter(
          (capability: Capability) => capability.id !== action.capabilityId,
        ),
      };

    default:
      return state;
  }
}
