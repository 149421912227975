import { FormInstance } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoggedInUser } from '../../auth/authState';
import {
  CancelButton,
  EditButton,
  ResetButton,
  SaveButton,
} from '../../common/components/ant/button';
import { Modal } from '../../common/components/ant/modal';
import Portal from '../../common/components/portal';
import { useSelectedOrgId } from '../../organizations/orgState';
import { editUser } from '../userActions';
import { UserForm } from './userActions';

const Profile: React.FC = () => {
  const loggedInUser = useLoggedInUser();
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const selectedOrgId = useSelectedOrgId();
  const [form, setForm] = useState<FormInstance<any>>();

  const { t } = useTranslation();

  const handleCancel = () => {
    setShowModal(false);
    setEditMode(false);
  };

  const handleSubmit = (values: any) => {
    editUser({ ...values, id: loggedInUser.id }, selectedOrgId);
    setEditMode(false);
  };

  const getModalFooter = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CancelButton
          onClick={() => {
            handleCancel();
          }}
        />
        {!editMode ? (
          <EditButton
            onClick={() => {
              setEditMode(true);
            }}
          />
        ) : (
          <div>
            <ResetButton
              onClick={() => {
                if (form) {
                  form.resetFields();
                  setEditMode(false);
                }
              }}
            />
            <SaveButton
              onClick={() => {
                if (form) {
                  form
                    .validateFields()
                    .then((values) => {
                      handleSubmit(values);
                    })
                    .catch((info) => console.log(info));
                }
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Portal selectorId="profile-container">
        <div
          className="dropdown-item"
          onClick={() => {
            setShowModal(true);
          }}
        >
          {t('users.profile')}
        </div>
      </Portal>
      <Modal
        visible={showModal}
        footer={getModalFooter()}
        closable={false}
        title={t('users.profile')}
        width="600px"
      >
        <UserForm
          editMode={editMode}
          handleSubmit={handleSubmit}
          setForm={setForm}
          initialValues={{
            ...loggedInUser,
            admin: loggedInUser.globalRoles ? loggedInUser.globalRoles.includes('admin') : false,
            dateOfBirth: loggedInUser.dateOfBirth ? moment(loggedInUser.dateOfBirth) : undefined,
          }}
        />
      </Modal>
    </>
  );
};

export default Profile;
