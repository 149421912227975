import axios from 'axios';
import { UserDateProperties } from '../users/userType';
import { convertStringToDate } from '../utils/propertyConverter';
import { AuthStatus, UserCredentials } from './authTypes';

const restURL = process.env.REACT_APP_API_ENDPOINT;

export function authenticate(credentials: UserCredentials) {
  return axios
    .post(`${restURL}/login`, credentials)
    .then((response) => {
      convertStringToDate(response.data, UserDateProperties);
      const token = response.headers.authorization;
      localStorage.setItem('token', token);
      return {
        user: response.data,
        status: AuthStatus.AUTHENTICATED,
      };
    })
    .catch((err) => {
      if (err.response && err.response.status < 500) {
        return {
          status: AuthStatus.AUTHENTICATION_ERROR,
          user: undefined
        };
      }
      return Promise.reject(err);
    });
}

export function generateResetPasswordLink(values: any) {
  return axios
    .post(`${restURL}/users/reset-token`, values)
    .then((response) => console.log(response));
}


export function resetPassword(values: any) {
  return axios
    .post(`${restURL}/users/reset-password`, values)
    .then((response) => response);
}
