import { FormInstance } from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Capability } from '../../../../capabilities/capabilityType';
import { DatePicker } from '../../../../common/components/ant/DatePicker';
import { FormItem } from '../../../../common/components/ant/form';
import { Input, TextArea } from '../../../../common/components/ant/input';
import { ValueSetSelect } from '../../../../common/components/valueSetSelectByCode';
import { endDateValidation, startDateValidation } from '../../../../utils/validation';
import { useValueSetItems } from '../../../../valueSets/valueSetState';
import { ValueSetCode, ValueSetItem } from '../../../../valueSets/valueSetType';

interface PersonalCapabilityProps {
  id: number;
  data: Capability;
  editMode: boolean;
  form?: FormInstance;
}
const PersonalCapability: React.FC<PersonalCapabilityProps> = ({ id, editMode, data, form }) => {
  const { t } = useTranslation();

  const valueSetItems = useValueSetItems(ValueSetCode.personal);

  const getLabel = (id?: number) => {
    if (id) {
      const item = valueSetItems
        ? valueSetItems.find((valueSet: ValueSetItem) => valueSet.id === id)
        : null;
      return <p style={{ margin: 0, fontSize: '16px' }}>{item && item.label}</p>;
    }
  };
  return (
    <>
      <div className="table-cell label-cell">
        <div className="table-cell-content">
          {id !== -1 ? (
            getLabel(data.typeId)
          ) : (
            <FormItem name="typeId" rules={[{ required: true }]}>
              <ValueSetSelect code={ValueSetCode.personal} />
            </FormItem>
          )}
        </div>
      </div>
      <div className="table-cell description-cell">
        <div className="table-cell-content">
          <FormItem name="description">
            <TextArea id={'desctiption' + id} viewMode={!editMode} autoSize />
          </FormItem>
        </div>
      </div>
      <div className="table-cell valid-from-cell">
        <div className="table-cell-content">
          <FormItem name="validFrom">
            <DatePicker
              id={'validFrom' + id}
              viewMode={!editMode}
              format="YYYY-MM-DD"
              placeholder={editMode ? 'YYYY-MM-DD' : ''}
              disabledDate={(current: Moment) =>
                startDateValidation(current, form ? form.getFieldValue('validTo') : '')
              }
            />
          </FormItem>
        </div>
      </div>
      <div className="table-cell valid-to-cell">
        <div className="table-cell-content">
          <FormItem name="validUntil">
            <DatePicker
              id={'validUntil' + id}
              viewMode={!editMode}
              format="YYYY-MM-DD"
              placeholder={editMode ? 'YYYY-MM-DD' : ''}
              disabledDate={(current: Moment) =>
                endDateValidation(current, form ? form.getFieldValue('validUntil') : '')
              }
            />
          </FormItem>
        </div>
      </div>
      <div className="table-cell notify-cell">
        <div className="table-cell-content">
          <FormItem name="notifyIn">
            <Input
              id={'notifyIn' + id}
              placeholder={editMode ? t('capabilities.expirationDatePlaceholder') : ''}
              viewMode={!editMode}
            />
          </FormItem>
        </div>
      </div>
    </>
  );
};

export default PersonalCapability;
