import './App.scss';
import React, { Suspense, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Login from './auth/components';
import AuthRoute from './auth/components/authRoute';
import Events from './events/components';
import { useSelector } from 'react-redux';
import { RootState } from './common/commonTypes';
import Navigation from './navigation';
import Organizations from './organizations/components';
import { getOrganizations } from './organizations/orgActions';
import { AuthStatus } from './auth/authTypes';
import { getValueSets } from './valueSets/valueSetActions';
import EventDetails from './events/components/eventDetails';
import Profile from './users/components/profile';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import ResetPassword from './auth/components/resetPassword';
import Users from './users/components';

library.add(far, fas);

const App: React.FC = () => {
  const authStatus = useSelector((state: RootState) => state.auth.status);

  useEffect(() => {
    if (authStatus === AuthStatus.AUTHENTICATED) {
      getOrganizations();
      getValueSets();
    }
  }, [authStatus]);

  return (
    <div className="root-container">
      <Suspense fallback={<div>Loading...</div>}>
        {authStatus === AuthStatus.AUTHENTICATED && (
          <>
            <Profile />
            <Navigation />
          </>
        )}
        <Switch>
          <AuthRoute exact path="/login" component={Login} type="guest" />
          <AuthRoute exact path="/reset-password" component={ResetPassword} type="guest" />
          <AuthRoute exact path="/" type="private">
            <Redirect to="/events" />
          </AuthRoute>
          <AuthRoute exact component={Events} type="private" path="/events" />
          <AuthRoute exact component={EventDetails} type="private" path="/events/:id/org/:orgId" />
          <AuthRoute exact component={EventDetails} type="private" path="/events/org/:orgId" />
          <AuthRoute exact component={Organizations} type="private" path="/organizations" />
          <AuthRoute exact component={Users} type="private" path="/users" />
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
