import { Auditable, Identitfiable, NormalizedObject } from '../common/commonTypes';

export interface OrganizationState {
  byId: NormalizedObject<Organization>;
  allIds: number[];
  selectedOrgId: number;
  expandedKeys: number[];
}

export interface OrganizationDeatils extends Identitfiable {
  code: string;
  name: string;
  address?: string;
  phone?: string;
  parentId?: number;
  parentCode?: string;
}

export interface Organization extends Auditable, OrganizationDeatils {
  orgEvents: number;
  childEvents: number;
  accountCount: number;
  childOrganizations?: Organization[];
  active?: boolean;
  children: number[];
}

export const initialOrgValues = {
  id: 0,
  code: '',
  name: '',
};