import React, { useState } from 'react';
import { authenticateUser } from '../authActions';
import { useTranslation } from 'react-i18next';
import './loginScreenStyle.scss';
import { AuthStatus, UserCredentials } from '../authTypes';
import { Form, FormItem, useForm } from '../../common/components/ant/form';
import { Input } from '../../common/components/ant/input';
import { Button } from '../../common/components/ant/button';
import Alert from '../../common/components/ant/alert';
import { generateResetPasswordLink } from '../authService';
import LoginScreen from './loginScreen';
import { handleErrors } from '../../utils/validation';
import { successMessage } from '../../common/components/ant/messages';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [showForgotPwForm, setShowForgotPwForm] = useState(false);

  return (
    <LoginScreen>
      <div className="login-form">
        <h1 className="login-title">
          {showForgotPwForm ? t('login.forgotpassword') : t('login.title')}
        </h1>
        {showForgotPwForm ? (
          <ForgotPwForm setShowForgotPwForm={setShowForgotPwForm} />
        ) : (
          <LoginForm setShowForgotPwForm={setShowForgotPwForm} />
        )}
      </div>
    </LoginScreen>
  );
};

export default Login;

interface LoginFormProps {
  setShowForgotPwForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginForm: React.FC<LoginFormProps> = ({ setShowForgotPwForm }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const { t, i18n } = useTranslation();

  const handleSubmit = (credentials: UserCredentials) => {
    authenticateUser(credentials)
      .then((checkedCredentials) => {
        if (checkedCredentials.status === AuthStatus.AUTHENTICATION_ERROR) {
          setErrorMsg(t('login.error.invalid'));
        }
      })
      .catch((error) => {
        setErrorMsg(t('login.error.invalid'));
      });
  };

  return (
    <>
      <div className={`error-container ${errorMsg ? 'visible' : ''}`}>
        {errorMsg && <Alert message={errorMsg} type="error" />}
        {/* {errorMsg && <span className="error-message">{errorMsg} </span>} */}
      </div>
      <Form onFinish={handleSubmit} layout="vertical" size="large">
        <FormItem
          name="username"
          label={t('login.username')}
          rules={[{ required: true, message: t('common.validation.field-empty') }]}
        >
          <Input />
        </FormItem>
        <FormItem
          name="password"
          label={t('login.password')}
          rules={[{ required: true, message: t('common.validation.field-empty') }]}
        >
          <Input type="password" />
        </FormItem>

        <div className="forgot-password-container">
          <a onClick={() => setShowForgotPwForm(true)} href="#">
            <span>{t('login.forgotpassword')}</span>
          </a>
        </div>
        <div className="login-button-container">
          <Button htmlType="submit" size="large" type="primary">
            {t('login.login')}
          </Button>
        </div>
      </Form>
    </>
  );
};

const ForgotPwForm: React.FC<LoginFormProps> = ({ setShowForgotPwForm }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation();
  const [form] = useForm();

  const handleSubmit = (values: any) => {
    generateResetPasswordLink(values)
      .then(() => {
        successMessage(t('login.emailsent'));
      })
      .catch((error) => handleErrors(error.response.data, t, form, setErrorMsg));
  };

  return (
    <>
      <div className={`error-container ${errorMsg ? 'visible' : ''}`}>
        {errorMsg && <Alert message={errorMsg} type="error" />}
      </div>
      <Form form={form} onFinish={handleSubmit} layout="vertical" size="large">
        <FormItem
          name="email"
          label={t('common.email')}
          rules={[{ required: true, message: t('common.validation.field-empty') }]}
        >
          <Input />
        </FormItem>

        <div className="forgot-password-container">
          <a onClick={() => setShowForgotPwForm(false)} href="#">
            <span>{t('common.back')}</span>
          </a>
        </div>
        <div className="login-button-container">
          <Button htmlType="submit" size="large" type="primary">
            {t('common.submit')}
          </Button>
        </div>
      </Form>
    </>
  );
};
