import { store } from '../common/rootReducer';
import { OrgActionTypes } from './orgActionTypes';
import * as orgService from './orgService';
import { normalizeOrgTree } from '../utils/normalizr';
import { OrganizationDeatils } from './orgTypes';

export const getOrganizations = () => {
  return orgService.getOrganizations().then((response) => {
    // store.dispatch({
    //   type: OrgActionTypes.SET_ORGANIZATION_TREE,
    //   payload: response,
    // });

    store.dispatch({
      type: OrgActionTypes.SET_ORGANIZATIONS,
      payload: normalizeOrgTree(response),
    });
  });
};

export const setSelectedOrg = (orgId: number) => {
  store.dispatch({
    type: OrgActionTypes.SET_SELECTED_ORG,
    payload: orgId,
  });
};

export const setExpandedKeys = (keys: number[]) => {
  store.dispatch({
    type: OrgActionTypes.SET_EXPANDED_KEYS,
    payload: keys,
  });
};

export const getOrganizationDetails = (organizationId: number) => {
  return orgService.getOrganizationDetails(organizationId).then((response) => {
    store.dispatch({
      type: OrgActionTypes.UPDATE_ORGANIZATIONS,
      id: organizationId,
      payload: response,
    });
  });
};

export const createOrganization = (organization: OrganizationDeatils) => {

  return orgService.createOrganization(organization).then((response) => {
    if(response.parentId) {
      store.dispatch({
        type: OrgActionTypes.ADD_NEW_CHILD,
        payload: response,
      });
    } else {
      store.dispatch({
        type: OrgActionTypes.UPDATE_ORGANIZATIONS,
        payload: response,
      });
    }
    return response;
  })
};

export const updateOrganization = (organization: OrganizationDeatils, id: number) => {
  return orgService.updateOrganization(organization, id).then((response) => {
    store.dispatch({
      type: OrgActionTypes.UPDATE_ORGANIZATIONS,
      payload: response,
    });

   return response;
  });
};
