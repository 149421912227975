import { FormInstance, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CreateButton } from '../../common/components/ant/button';
import { Form, useForm } from '../../common/components/ant/form';
import { Input } from '../../common/components/ant/input';
import { successMessage } from '../../common/components/ant/messages';
import { Modal } from '../../common/components/ant/modal';
import { ContentWrapper } from '../../common/components/pageContent/contentWrapper';
import { LayoutWrapper } from '../../common/components/pageContent/layoutWrapper';
import { handleErrors } from '../../utils/validation';
import { createUser, editAccount, editUser, getUsers } from '../userService';
import { initialUser, Membership, User } from '../userType';
import { UserActions } from './userActions';
import UserList from './userList';

const Users: React.FC = () => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<User[]>([]);

  const [filterBy, setFilterBy] = useState('nameStartsWith');
  const [filterValue, setFilterValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emptyText, setEmptyText] = useState<string | undefined>(t('users.filterCriteria'));

  const options = [
    { label: t('common.name'), value: 'nameStartsWith' },
    { label: t('common.email'), value: 'emailStartsWith' },
  ];

  const onChange = (e: any) => {
    setFilterBy(e.target.value);
  };

  const search = () => {
    setEmptyText(undefined);
    let queryString = filterBy + '=' + filterValue;
    getUsers(queryString).then((response) => {
      setUsers(response);
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSubmitUser = (values: User, form: FormInstance<any>) => {
    createUser(values)
      .then(() => {
        successMessage(t('common.message.addSuccess'));
        if (filterValue) {
          search();
        }
        handleCancel();
      })
      .catch((error) => handleErrors(error.response.data, t, form));
  };

  const handleEditUser = (values: any, form: FormInstance<any> | undefined) => {
    editUser(values)
      .then(() => {
        if (filterValue) {
          search();
        }
        handleCancel();
      })
      .catch((error) => handleErrors(error.response.data, t, form));
  };

  const handleSubmitAccount = (values: Membership, form: FormInstance<any> | undefined) => {
    return editAccount(values)
      .then(() => {
        if (filterValue) {
          search();
        }
        handleCancel();
        Promise.resolve();
      })
      .catch((error) => {
        handleErrors(error.response.data, t, form);
        Promise.reject();
      });
  };

  return (
    <LayoutWrapper>
      <ContentWrapper>
        <UserActions
          showModal={showModal}
          handleCancel={handleCancel}
          handleSubmit={(values, form) => handleSubmitUser(values, form)}
          initialValues={initialUser}
          title={t('users.addTitle')}
          editMode={true}
        />
        {/* <Modal
        destroyOnClose
        visible={showModal}
        onCancel={handleCancel}
        onOk={() => {
          if (form) {
            form.submit();
          }
        }}
        title={t('users.addTitle')}
      >
        <AddUserForm handleSubmit={handleSubmitUser} setFrom={setForm} />
      </Modal> */}
        <div className="filters">
          <div>
            <CreateButton text={t('users.add')} onClick={() => setShowModal(true)} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="filter-by">{t('common.filterBy')}:</span>

            <div className="radio-group">
              <Radio.Group onChange={onChange} value={filterBy}>
                {options.map((opt) => (
                  <Radio key={opt.value} value={opt.value}>
                    {opt.label}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
            <div className="filter-input">
              <Input
                onChange={(e) => setFilterValue(e.target.value)}
                placeholder="min 3 character"
              />
            </div>

            <Button
              className="save search-button"
              onClick={() => search()}
              disabled={filterBy && filterValue.length > 3 ? false : true}
            >
              {t('common.search')}
            </Button>
          </div>
        </div>

        <UserList
          users={users}
          handleSubmitUser={handleEditUser}
          handleSubmitAccount={handleSubmitAccount}
          isOrgContext={false}
          emptyText={emptyText}
        />
      </ContentWrapper>
    </LayoutWrapper>
  );
};

export default Users;

// interface AddUserFormProps {
//   handleSubmit: (values: User) => void;
//   setFrom: React.Dispatch<React.SetStateAction<FormInstance<any> | undefined>>;
// }
// const AddUserForm: React.FC<AddUserFormProps> = ({ handleSubmit, setFrom }) => {
//   const [form] = useForm();

//   useEffect(() => {
//     setFrom(form);
//   }, [form]);

//   return (
//     <Form form={form} size="large" layout="vertical" onFinish={(values) => handleSubmit(values)}>
//       <UserFormItems editMode={true} />
//     </Form>
//   );
// };
