import moment from 'moment';
import { Capability, CapabilityOfferStats } from '../capabilities/capabilityType';
import { Auditable, Identitfiable, NormalizedState } from '../common/commonTypes';

export interface RegistrationDetails extends RegistrationSummary {
  event: EventSummary;
}

export interface EventSummary extends Auditable, Identitfiable {
  summary: string;
  description?: string;
  plannedStart: moment.Moment;
  plannedEnd?: moment.Moment;
  typeId: number;
  state: string;
  organizerOrgId: number;
  organizerOrgName: string;
  openForRegistration: boolean;
  registrations: RegistrationSummary[];
  capabilities: NormalizedState<CapabilityOfferStats>;
  invitedOrgIds?: number[]; // only present when events are listed for a specific user, lists all organizations where the user has an account that can be used for the registration
  invites: Invite[];
  notifyAllBeforeStart?: boolean;     // true: notify all invitees before planned start, false: notify only registered users before planned start, defaults to false
  notifyBeforeStart?: number; // notify [all invitees / registered users only] X minutes before planned start
  notifyWhenCompleted?: boolean;  // true: notify registered users when event is completed, defaults to false
}

export interface Invite extends Auditable, Identitfiable {
  orgId: number;
  children: 0 | 1 | null;
}

export interface RegistrationSummary extends Auditable, Identitfiable {
  userId: number;
  firstName?: string;
  lastName?: string;
  orgId: number;
  userAlias: string;
  availableFrom?: Date;
  availableUntil?: Date;
  state: string; // ACCEPTED | DECLINED     |     APPROVED | REJECTED
  capabilities?: Capability[];
  arrivingIn?: number;
  availableFor?: number;
}

export const EventDateProperties: string[] = ['plannedStart', 'plannedEnd'];

export const EventState = {
  PLANNED: 'PLANNED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export interface EventActionsByState {
  [key: string]: string[];
}

export const eventActionsByState: EventActionsByState = {
  PLANNED: ['IN_PROGRESS', 'CANCELLED'],
  IN_PROGRESS: ['COMPLETED', 'CANCELLED'],
  COMPLETED: [],
  CANCELLED:[]
};

export const EventRegistrationState = {
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}

export const CapabilityOfferState = {
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  OFFERED: 'OFFERED',
}

export interface RegistrationState {
  state: string
}

export interface TagColorByState {
  [key: string]: string;
}
export const tagColorByState : TagColorByState = {
  DECLINED: 'red',
  ACCEPTED: 'green',
  APPROVED: 'green',
  REJECTED: 'red'
}

export const SendNotificationTo = {
  ALL: 'ALL',
  ATTENDEES: 'ATTENDEES',
}

export const initialEventSummary = {
  plannedStart: moment(new Date()),
  openForRegistration: true,
}