import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLoggedInUser } from '../../auth/authState';
import { ButtonByActionType } from '../../common/components/ant/button';
import Table from '../../common/components/ant/table';
import Tooltip from '../../common/components/ant/tooltip';
import { useOrganizations } from '../../organizations/orgState';
import { OrganizationDeatils } from '../../organizations/orgTypes';
import { roleTypes } from '../../users/userType';
import { hasPermission, RoleChecker } from '../../utils/roleChecker';
import { useValueSetItems } from '../../valueSets/valueSetState';
import { ValueSetCode, ValueSetItem } from '../../valueSets/valueSetType';
import { getRegistrations, registrationStatusUpdate } from '../eventActions';
import { useEventRegistrations, useEventById } from '../eventState';
import { EventRegistrationState, RegistrationSummary } from '../eventType';

const RegistrationDetails: React.FC = () => {
  const { id, orgId } = useParams<{ id?: string; orgId?: string }>();
  const organizations = useOrganizations();
  const registrations = useEventRegistrations();
  const eventProps = useEventById(id ? parseInt(id) : 0);
  const event = eventProps.event;
  const eventIsActive = eventProps.isActive;

  const { t } = useTranslation();

  useEffect(() => {
    if (id && orgId) {
      getRegistrations(parseInt(orgId), parseInt(id));
    }
  }, []);

  const groupByOrganization = () => {
    let orgIds: number[] = [];
    organizations.allIds.forEach((orgId: number) => {
      if (registrations && registrations[orgId]) {
        orgIds.push(orgId);
      }
    });

    return orgIds.map((id) => {
      const org = { ...organizations.byId[id] };
      org.children = [];
      return org;
    });
  };

  const findAllRegistration = (orgId: number, action: string) => {
    if (orgId) {
      const filteredRegistrations = registrations[orgId].filter((reg: RegistrationSummary) => {
        if (action === EventRegistrationState.APPROVED) {
          return (
            reg.state === EventRegistrationState.ACCEPTED ||
            reg.state === EventRegistrationState.REJECTED
          );
        }
        if (action === EventRegistrationState.REJECTED) {
          return reg.state === EventRegistrationState.APPROVED;
        }
      });
      return filteredRegistrations.map((reg: RegistrationSummary) => reg.id);
    }

    return [];
  };

  const actionButton = (orgId: number, action: string) => {
    let isAllRegistrationAccepted = true;
    let isAllRegistrationDeclined = true;
    registrations[orgId].forEach((reg: RegistrationSummary) => {
      if (reg.state !== EventRegistrationState.APPROVED) {
        isAllRegistrationAccepted = false;
      }

      if (reg.state !== EventRegistrationState.REJECTED) {
        isAllRegistrationDeclined = false;
      }
    });

    return (
      <ButtonByActionType
        action={action.toLowerCase()}
        disabled={
          !eventIsActive ||
          (action === EventRegistrationState.APPROVED && isAllRegistrationAccepted) ||
          (action === EventRegistrationState.REJECTED && isAllRegistrationDeclined)
        }
        style={{ marginRight: '8px' }}
        onClick={() => {
          const registrations = findAllRegistration(orgId, action).toString();
          if (registrations) {
            registrationStatusUpdate(registrations, parseInt(id!), orgId, {
              state: action,
            });
          }
        }}
      />
    );
  };

  const getAllRegistrationsNumber = () => {
    let total = 0;

    groupByOrganization().map((org) => (total += registrations[org.id].length));

    return total;
  };

  const getAllRegistrationsNumberByState = (recordId: number, state: string) => {
    let total = 0;
    registrations[recordId].forEach((el) => {
      if (el.state === state) {
        ++total;
      }
    });
    return total;
  };

  const columns: ColumnsType<OrganizationDeatils> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <div>
          {t('events.registration.sum')} ({getAllRegistrationsNumber()})
        </div>
      ),
      key: 'regSum',
      render: (record: OrganizationDeatils) => registrations[record.id].length,
    },
    {
      title: t('events.registration.state.APPROVED'),
      key: 'approved',
      align: 'center',
      render: (record: OrganizationDeatils) =>
        getAllRegistrationsNumberByState(record.id, EventRegistrationState.APPROVED),
    },
    {
      title: t('events.registration.state.REJECTED'),
      key: 'rejected',
      align: 'center',
      render: (record: OrganizationDeatils) =>
        getAllRegistrationsNumberByState(record.id, EventRegistrationState.REJECTED),
    },
    {
      key: 'action',
      align: 'right',
      render: (record: OrganizationDeatils) => (
        <RoleChecker roles={[roleTypes.ORGANIZER, roleTypes.ADMIN]} orgId={event!.organizerOrgId}>
          <>
            {actionButton(record.id, EventRegistrationState.APPROVED)}
            {actionButton(record.id, EventRegistrationState.REJECTED)}
          </>
        </RoleChecker>
      ),
    },
  ];

  return (
    <>
      {registrations && (
        <div className="table-container">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: (record) => <ExpandedRow organization={record} />,
            }}
            dataSource={groupByOrganization()}
            pagination={false}
          />
        </div>
      )}
    </>
  );
};

export default RegistrationDetails;

interface ExpandedRowProps {
  organization: OrganizationDeatils;
}
interface IconByStateProps {
  [key: string]: any;
}

const ExpandedRow: React.FC<ExpandedRowProps> = ({ organization }) => {
  const { id, orgId } = useParams<{ id?: string; orgId?: string }>();
  const personalValueSetItems = useValueSetItems(ValueSetCode.personal);
  const registrations = useEventRegistrations();
  const eventProps = useEventById(id ? parseInt(id) : 0);
  const event = eventProps.event;
  const loggedInUser = useLoggedInUser();

  const getRegistrationsByOrg = () => {
    const reg: RegistrationSummary[] = registrations[organization.id];
    return reg ? reg : [];
  };

  const { t } = useTranslation();

  const IconByState: IconByStateProps = {
    ACCEPTED: <QuestionCircleOutlined className="offered" />,
    REJECTED: <CloseCircleOutlined className="declined" />,
    APPROVED: <CheckCircleOutlined className="accepted" />,
  };

  const actionButton = (regId: number, action: string, record: RegistrationSummary) => {
    return (
      <ButtonByActionType
        disabled={record.state === action}
        action={action.toLowerCase()}
        style={{ marginRight: '8px' }}
        onClick={() => {
          registrationStatusUpdate(regId.toString(), parseInt(id!), record.orgId, {
            state: action,
          });
        }}
      />
    );
  };

  const columns: ColumnsType<RegistrationSummary> = [
    {
      title: t('common.name'),
      width: '30%',
      key: 'name',
      render: (record: RegistrationSummary) => {
        return (
          <>{`${record.lastName ? record.lastName : ''} ${
            record.firstName ? record.firstName : ''
          }`}</>
        );
      },
    },
    {
      key: 'capabilities',
      width: '35%',
      render: (record: RegistrationSummary) => {
        return (
          <>
            {record.capabilities
              ? record.capabilities.map((capability) => {
                  const valueSetItem = personalValueSetItems
                    ? personalValueSetItems.find(
                        (valueSet: ValueSetItem) => valueSet.id === capability.typeId,
                      )
                    : null;
                  return (
                    <p style={{ margin: 0 }} key={capability.id}>
                      {valueSetItem && valueSetItem.label}
                    </p>
                  );
                })
              : null}
          </>
        );
      },
    },

    // {
    //   key: 'state',
    //   render: (record: RegistrationSummary) => (
    //     <Tag color={tagColorByState[record.state]}>
    //       {t(`events.registration.state.${record.state}`)}
    //     </Tag>
    //   ),
    // },

    {
      key: 'arrivingIn',
      width: '10%',
      render: (record: RegistrationSummary) => (
        <Tooltip overlay={t('events.registration.arrivingIn')}>
          <div>
            <span>{record.arrivingIn}</span>
            <span style={{ paddingLeft: '3px' }}>{record.arrivingIn ? t('common.hours') : ''}</span>
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'availableFor',
      width: '10%',
      render: (record: RegistrationSummary) => (
        <Tooltip overlay={t('events.registration.availableFor')}>
          <div>
            <span>{record.availableFor}</span>
            <span style={{ paddingLeft: '3px' }}>
              {record.availableFor ? t('common.hours') : ''}
            </span>
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'action',
      align: 'right',
      width: '15%',
      render: (record: RegistrationSummary) => {
        if (
          hasPermission(
            [roleTypes.ORGANIZER, roleTypes.ADMIN],
            loggedInUser,
            parseInt(orgId!),
            event!.organizerOrgId,
          )
        ) {
          return (
            <>
              {actionButton(record.id, EventRegistrationState.APPROVED, record)}
              {actionButton(record.id, EventRegistrationState.REJECTED, record)}
            </>
          );
        }
        return IconByState[record.state];
      },
    },
  ];

  return (
    <>
      {registrations && (
        <div className="table-container">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={getRegistrationsByOrg()}
            pagination={false}
            className="registrations-table"
          />
        </div>
      )}
    </>
  );
};
