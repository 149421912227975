// ---- ROOT STATE TYPES ----
import { AuthState } from '../auth/authTypes';
import { Capability, CapabilityOfferStats } from '../capabilities/capabilityType';
import { EventSummary, RegistrationSummary } from '../events/eventType';
import { OrganizationState } from '../organizations/orgTypes';
import { User } from '../users/userType';
import { ValueSet } from '../valueSets/valueSetType';

export interface RootState {
  auth: AuthState;
  organizations: OrganizationState;
  usersByOrgId: NormalizedObject<User[]>;
  capabilitiesByOrgId: NormalizedObject<Capability[]>;
  valueSets: ValueSet[];
  activeEvents: NormalizedState<EventSummary>;
  closedEvents: NormalizedState<EventSummary>;
  registrationsByOrg: NormalizedObject<RegistrationSummary[]>
  eventCapabilities: NormalizedState<CapabilityOfferStats>
}

export interface NormalizedState<T> {
  byId: NormalizedObject<T>;
  allIds: number[];
}

export interface NormalizedObject<T> {
  [id: number]: T;
}

export interface Identitfiable {
  id: number;
}

export interface Auditable {
  createdBy?: number;
  createdAt?: Date;
  modifiedBy?: number;
  modifiedAt?: Date;
}

export interface ComponentType {
  [key: number]: JSX.Element;
}

export const CommonDateProperties: string[] = ['createdAt', 'modifiedAt'];

export const languages = [
  {name: "english", alias: 'EN', value: 'en' },
  {name: "magyar", alias: 'HU', value: 'hu' },
  {name: "‪română‬", alias: 'RO', value: 'ro' },
  {name: "slovenčina", alias: 'SK', value: 'sk' },
];

export const MessageType = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
}