import { store } from '../common/rootReducer';
import * as authService from './authService';
import { AuthActionTypes } from './authActionTypes';
import { AuthStatus, UserCredentials } from './authTypes';
import i18n from '../i18n';

export const logOut = () => {
  localStorage.removeItem('token');
  store.dispatch({
    type: AuthActionTypes.SET_AUTH_SESSION_STATUS,
    payload: AuthStatus.NOT_AUTHENTICATED,
  });
  store.dispatch({
    type: AuthActionTypes.USER_LOGOUT,
  });
};

export const authenticateUser = (credentials: UserCredentials) => {
  return authService.authenticate(credentials).then((checkedCredentials) => {
    if (checkedCredentials.status === AuthStatus.AUTHENTICATED) {
      if(checkedCredentials.user && checkedCredentials.user.language) {
        i18n.changeLanguage(checkedCredentials.user.language);
      }
      
      store.dispatch({
        type: AuthActionTypes.SET_ACTIVE_SESSION,
        payload: checkedCredentials,
      });
    }
    return checkedCredentials;
  });
};

