import { Form as AntForm, FormItemProps, FormProps } from 'antd';
import { ReactNode } from 'react';
import { useValidateMessages } from '../../../utils/useValidateMessages';

interface IFormProps extends FormProps {
  children: ReactNode;
}

export const Form: React.FC<IFormProps> = (props) => {
  const validateMessages = useValidateMessages();
  return (
    <AntForm validateMessages={validateMessages} layout="vertical" size="large" {...props}>
      {props.children}
    </AntForm>
  );
};

export const FormItem = (props: FormItemProps) => {
  return <AntForm.Item {...props}>{props.children}</AntForm.Item>;
};

export const FormList = (props: any) => {
  return <AntForm.List {...props}>{props.children}</AntForm.List>;
};

export const useForm = () => {
  return AntForm.useForm();
};
