import React from 'react';
import { useValueSetItems } from '../../valueSets/valueSetState';
import { ValueSetItem } from '../../valueSets/valueSetType';
import { SelectProps } from 'antd';
import { Select, Option } from './ant/select';

interface ValueSetSelectProps<T> extends SelectProps<T> {
  code: string;
  viewMode?: boolean;
}
export const ValueSetSelect: React.FC<ValueSetSelectProps<any>> = ({ code, ...props }) => {
  const valueSetItems = useValueSetItems(code);

  return (
    <Select {...props}>
      {valueSetItems &&
        valueSetItems.map((item: ValueSetItem) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          );
        })}
    </Select>
  );
};
