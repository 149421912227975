import { CheckCircleFilled, ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';
import { message } from 'antd';
import './antStyle.scss';

export const successMessage = (msg: string) => {
  message.success({
    content: msg,
    className: 'custom-message success',
    icon: <CheckCircleFilled />,
  });
};

export const errorMessage = (msg: string) => {
  message.error({
    content: msg,
    className: 'custom-message error',
    icon: <ExclamationCircleFilled />,
  });
};

export const warningMessage = (msg: string) => {
  message.warning({
    content: msg,
    className: 'custom-message warning',
    icon: <WarningFilled />,
  });
};
