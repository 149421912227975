import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import React from 'react';
import AccountsForm from '../../organizations/components/members/details/accountForm';
import PersonalCapability from '../../organizations/components/members/details/personalCapabilityForm';
import { Membership, roleTypes } from '../../users/userType';
import { RoleChecker } from '../../utils/roleChecker';
import { Button, TestButton } from './ant/button';
import { Form, useForm } from './ant/form';

interface Column {
  className: string;
  title: string;
}
interface EditableTableProps {
  columns: Column[];
  dataSource: any;
  handleSubmit: (values: any, form: FormInstance<any>) => void;
  selectedRow: number;
  setSelectedRow: React.Dispatch<React.SetStateAction<number>>;
  handleSubmitNewRow?: (values: any, from: FormInstance<any>) => void;
  handleDelete?: (id: number) => void;
  primaryOrgId?: number;
}

const EditableTable =
  (Component: any): React.FC<EditableTableProps> =>
  ({
    columns,
    dataSource,
    handleSubmit,
    selectedRow,
    setSelectedRow,
    handleSubmitNewRow,
    handleDelete,
    primaryOrgId,
  }) => {
    return (
      <>
        <div className="table-collapse">
          <div className="table-row table-row-header">
            {columns.map((column: Column) => {
              return (
                <div key={column.title} className={`table-cell column-header ${column.className}`}>
                  {column.title}
                </div>
              );
            })}
          </div>

          {dataSource.map((data: any) => {
            return (
              <EditableTableRow
                key={data.id}
                data={data}
                handleSubmit={handleSubmit}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                handleDelete={handleDelete}
                primaryOrgId={primaryOrgId}
              >
                <Component id={data.id} editMode={data.id === selectedRow} data={data} />
              </EditableTableRow>
            );
          })}

          {handleSubmitNewRow && (
            <NewItemTableRow
              handleSubmit={(values: any, form: FormInstance<any>) => {
                handleSubmitNewRow(values, form);
              }}
              primaryOrgId={primaryOrgId}
            >
              <Component editMode={true} id={-1} />
            </NewItemTableRow>
          )}
        </div>
      </>
    );
  };

interface NewItemTableRowProps {
  handleSubmit: (values: any, form: FormInstance<any>) => void;
  primaryOrgId?: number;
}
const NewItemTableRow: React.FC<NewItemTableRowProps> = ({
  handleSubmit,
  primaryOrgId,
  children,
}) => {
  const [form] = useForm();
  return (
    <Form
      form={form}
      onFinish={(values: Membership) => {
        handleSubmit(values, form);
      }}
      style={{ width: '100%' }}
      className={`table-row`}
    >
      <RoleChecker roles={[roleTypes.ADMIN]} orgId={primaryOrgId}>
        {children}
        <div className="table-cell actions-cell">
          <div className="table-cell-content">
            <TestButton htmlType="submit" />
          </div>
        </div>
      </RoleChecker>
    </Form>
  );
};

interface EditableTableRowProps {
  data: any;
  handleSubmit: (values: any, form: FormInstance<any>) => void;
  selectedRow: number;
  setSelectedRow: React.Dispatch<React.SetStateAction<number>>;
  handleDelete?: (id: number) => void;
  primaryOrgId?: number;
}
const EditableTableRow: React.FC<EditableTableRowProps> = ({
  data,
  handleSubmit,
  selectedRow,
  setSelectedRow,
  children,
  handleDelete,
  primaryOrgId,
}) => {
  const [form] = useForm();

  return (
    <Form
      key={data.id}
      form={form}
      initialValues={data}
      onFinish={(values: Membership) => handleSubmit({ ...data, ...values }, form)}
      style={{ width: '100%' }}
      className={`table-row ${selectedRow && data.id !== selectedRow ? 'inactive' : ''}`}
    >
      {children}
      <div className="table-cell actions-cell">
        <RoleChecker roles={[roleTypes.ADMIN]} orgId={primaryOrgId}>
          <div className="table-cell-content">
            {data.id !== selectedRow ? (
              <>
                <Button
                  icon={<EditOutlined />}
                  className="save"
                  onClick={() => {
                    setSelectedRow(data.id);
                  }}
                />
                {handleDelete && (
                  <Button
                    onClick={() => handleDelete!(data.id)}
                    icon={<DeleteOutlined />}
                    className="delete"
                    style={{ marginLeft: '5px' }}
                  />
                )}
              </>
            ) : (
              <>
                <TestButton htmlType="submit" />
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => {
                    form.resetFields();
                    setSelectedRow(0);
                  }}
                  style={{ marginLeft: '5px' }}
                />
              </>
            )}
          </div>
        </RoleChecker>
      </div>
    </Form>
  );
};

export const AccountsTable = EditableTable(AccountsForm);
export const PersonalCapabilitiesTable = EditableTable(PersonalCapability);
