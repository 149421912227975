import { store } from '../common/rootReducer';
import { CapabilityActionTypes } from './capabilityActionTypes';
import * as capabilityService from './capabilityService';
import { Capability } from './capabilityType';

export const addCapabilityToOrg = (capability: Capability, organizationId: number) => {
  return capabilityService.addCapability(capability).then((response) => {
    store.dispatch({
      type: CapabilityActionTypes.ADD_ORG_CAPABILITY,
      id: organizationId,
      payload: response,
    });
  });
};

export const editOrgCapability = (capability: Capability, organizationId: number) => {
  return capabilityService.editCapability(capability).then((response) => {
    store.dispatch({
      type: CapabilityActionTypes.UPDATE_ORG_CAPABILITY,
      id: organizationId,
      payload: response,
    });
  });
};

export const deleteOrgCapability = (capabilityId: number, organizationId: number) => {
  return capabilityService.deleteCapability(capabilityId).then(() => {
    store.dispatch({
      type: CapabilityActionTypes.DELETE_ORG_CAPABILITY,
      orgId: organizationId,
      capabilityId: capabilityId,
    });
  });
};

export const getOrgCapabilites = (organizationId: number) => {
  return capabilityService.getOrganizationCapabilites(organizationId).then((response) => {
    store.dispatch({
      type: CapabilityActionTypes.SET_ORG_CAPABILITES,
      id: organizationId,
      payload: response,
    });
  });
};
