import { DownOutlined } from '@ant-design/icons';
import { TreeSelect as AntTreeSelect } from 'antd';
import { TreeSelectProps } from 'antd';
import React from 'react';
import './antStyle.scss';

interface ITreeSelect<T> extends TreeSelectProps<T> {
  viewMode?: boolean;
}

const TreeSelect: React.FC<ITreeSelect<any>> = ({ viewMode, ...props }) => {
  return (
    <AntTreeSelect
      disabled={viewMode}
      bordered={!viewMode}
      showArrow={!viewMode}
      {...props}
      switcherIcon={<DownOutlined />}
      className={`custom-select ${viewMode ? 'view-mode' : ''}  ${props.className}`}
    />
  );
};
export default TreeSelect;
