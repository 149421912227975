import axios from 'axios';
import { OrganizationDeatils } from './orgTypes';

const restURL = process.env.REACT_APP_API_ENDPOINT;

export function getOrganizations() {
  return axios.get(`${restURL}/organizations`).then((response) => {
    return response.data;
  });
}

export function createOrganization(organization: OrganizationDeatils) {
  return axios.post(`${restURL}/organizations`, organization).then((response) => {
    return response.data;
  });
}

export function updateOrganization(organization: OrganizationDeatils, id: number) {
  return axios.put(`${restURL}/organizations/${id}`, organization).then((response) => {
    return response.data;
  });
}

export function getOrganizationDetails(id: number) {
  return axios.get(`${restURL}/organizations/` + id).then((response) => {
    return response.data;
  });
}

