import { FormInstance } from 'antd';
import { Moment } from 'moment';
import { errorMessage } from '../common/components/ant/messages';

export const startDateValidation = (current: Moment, endDate?: Moment): boolean => {
  if (!endDate) {
    return false;
  }
  return current.isAfter(endDate, 'day');
};

export const endDateValidation = (current: Moment, startDate?: Moment): boolean => {
  if (!startDate) {
    return false;
  }
  return current.isBefore(startDate, 'day');
};

export const handleErrors = (
  error: any,
  t: any,
  form?: FormInstance,
  setErrorMessages?: any,
  handleCancel?: () => void,
) => {
  console.log(error)
  if (error.fieldErrors) {
    error.fieldErrors.forEach((err: { key: string; message: string, errors: any[] }) => {
      if(err.errors) {
        err.errors.forEach((err: { key: string; message: string, errors: any[] }) => {
          if (form && form.getFieldValue(err.key)) {
            form.setFields([
              {
                name: err.key,
                errors: [err.message],
              },
            ]);
          } else {
            if (err.message && setErrorMessages) {
              setErrorMessages(err.message);
            }
          }
        })
      }
      if (form && form.getFieldValue(err.key)) {
        form.setFields([
          {
            name: err.key,
            errors: [err.message],
          },
        ]);
      } else {
        if (err.message && setErrorMessages) {
          setErrorMessages(err.message);
        }
      }
    });
  } else {
    errorMessage(t('message.error.default'));

    if (handleCancel) {
      handleCancel();
    }
  }
};
