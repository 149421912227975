import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentType } from '../../common/commonTypes';
import { ContentWrapper } from '../../common/components/pageContent/contentWrapper';
import { LayoutWrapper } from '../../common/components/pageContent/layoutWrapper';
import { TabBar } from '../../common/components/pageContent/tabBar';
import { getOrganizationMembers } from '../../users/userActions';
import { useSelectedOrgId } from '../orgState';
import Capabilities from './capabilities';
import Details from './details';
import Members from './members';
import './orgStyle.scss';

const Organizations: React.FC = () => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(0);
  const tabs = [t('events.capabilities'), t('organizations.members.title')];
  const [showDetails, setShowDetails] = useState(false);
  const selectedOrgId = useSelectedOrgId();

  const components: ComponentType = {
    0: <Capabilities />,
    1: <Members />,
  };

  useEffect(() => {
    if (selectedOrgId) {
      getOrganizationMembers(selectedOrgId);
    }
  }, [selectedOrgId]);

  return (
    <LayoutWrapper>
      <TabBar
        showOrgTree={true}
        tabs={tabs}
        setActiveKey={(index) => setActiveKey(index)}
        activeKey={activeKey}
        customClass={showDetails ? 'active' : ''}
      >
        <Details showDetails={(show: boolean) => setShowDetails(show)} />
      </TabBar>
      <ContentWrapper>{components[activeKey]}</ContentWrapper>
    </LayoutWrapper>
  );
};

export default Organizations;
