import React from 'react';
import { useTranslation } from 'react-i18next';
import './pageContent.scss';

export const LayoutWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="main-content-container">
      <div className="content">{children}</div>
      <footer>
        <div className="footer-text-container">
          <a href="https://www.skhu.eu" target="_blank">
            {t('footer.riskhubText')}
          </a>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div className="footer-logo-container">
            <img className="footer-logo-image-big" src="/static/profound.png" alt="Profound" />
            <img
              className="footer-logo-image"
              src="/static/funded_by_the_EU.png"
              alt="Funded by the EU"
            />
          </div>
          <div className="footer-logo-container">
            <img className="footer-logo-image-big" src="/static/riskhub.png" alt="Riskhub" />
            <div className="footer-logo-merged-elements">
              <img
                className="footer-logo-image"
                style={{ marginBottom: 0 }}
                src="/static/interreg_hu_sk.png"
                alt="Interreg HU SK"
              />
              <img className="slogen-image" src="/static/slogen_hu.png" alt="Slogen hu" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
