import React, { useEffect, useState } from 'react';
import { Button, CreateButton, EditButton } from '../../../common/components/ant/button';
import Portal from '../../../common/components/portal';
import { createOrganization, getOrganizationDetails, updateOrganization } from '../../orgActions';
import { useOrganizations, useSelectedOrgId } from '../../orgState';
import { initialOrgValues, OrganizationDeatils } from '../../orgTypes';
import OrgActions from './orgActions';
import { useTranslation } from 'react-i18next';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { roleTypes } from '../../../users/userType';
import { RoleChecker } from '../../../utils/roleChecker';
import { FormInstance } from 'antd';
import { handleErrors } from '../../../utils/validation';

interface DetailsProps {
  showDetails: any;
}
const Details: React.FC<DetailsProps> = ({ showDetails }) => {
  const selectedOrgId = useSelectedOrgId();
  const organizations = useOrganizations();
  const organization = organizations.byId[selectedOrgId];

  const [showOrgDetails, setShowOrgDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [parentId, setParentId] = useState(0);
  // const [initialValues, setInitialValues] = useState<OrganizationDeatils>(initialOrgValues);
  const [title, setTitle] = useState('');

  const { t } = useTranslation();

  const handleCancel = () => {
    setShowModal(false);
    setParentId(0);
  };

  const handleSubmit = (values: OrganizationDeatils, form: FormInstance) => {
    if (parentId) {
      values = { ...values, parentId: selectedOrgId, parentCode: organization.code };
      createOrganization(values)
        .then(() => handleCancel())
        .catch((error) => {
          handleErrors(error.response.data, t, form, handleCancel);
        });
    } else {
      updateOrganization(values, selectedOrgId)
        .then(() => handleCancel())
        .catch((error) => {
          handleErrors(error.response.data, t, form, handleCancel);
        });
    }
  };

  useEffect(() => {
    if (selectedOrgId) {
      getOrganizationDetails(selectedOrgId);
    }
  }, [selectedOrgId]);

  return (
    <>
      <Portal selectorId="create-root-container">
        <div
          className="dropdown-item"
          onClick={() => {
            setShowModal(true);
            setTitle(t('organizations.actions.newRootOrgTitle'));
          }}
        >
          {t('organizations.actions.newRootOrg')}
        </div>
      </Portal>

      <OrgActions
        showModal={showModal}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        initialValues={parentId ? initialOrgValues : organization}
        title={title}
      />

      {organization && (
        <div className="details-container">
          <Portal selectorId="create-child-container">
            <CreateButton
              onClick={() => {
                setShowModal(true);
                setTitle(t('organizations.actions.newChildOrgTitle'));
                setParentId(selectedOrgId);
              }}
              text={t('organizations.actions.newChildOrg')}
            />
          </Portal>
          <Portal selectorId="org-details-container">
            <Button
              className="info"
              onClick={() => {
                setShowOrgDetails(!showOrgDetails);
                showDetails(!showOrgDetails);
              }}
            >
              {showOrgDetails ? (
                <>
                  <CaretUpOutlined /> {t('common.hideDetails')}
                </>
              ) : (
                <>
                  <CaretDownOutlined /> {t('common.showDetails')}
                </>
              )}
            </Button>
            <RoleChecker roles={[roleTypes.ADMIN]}>
              {showOrgDetails && (
                <EditButton
                  style={{ marginLeft: '8px' }}
                  onClick={() => {
                    setTitle(t('organizations.actions.editOrganization'));
                    setShowModal(true);
                  }}
                />
              )}
            </RoleChecker>
          </Portal>
          <div className="org-info-container">
            <div className="box-conatiner">
              <div className="org-data">
                <div className="box">
                  <span className="label">{t('organizations.code')}</span>{' '}
                  <span className="value">{organization.code}</span>
                </div>
              </div>
              <div className="org-data">
                <div className="box">
                  <span className="label">{t('organizations.address')}</span>{' '}
                  <span className="value">{organization.address}</span>
                </div>
                <div className="box">
                  <span className="label">{t('organizations.phoneNumber')}</span>{' '}
                  <span className="value">{organization.phone}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Details;
