import { InputNumberProps, InputNumber as AntInputNumber } from 'antd';
import './antStyle.scss';

interface IInputNumberProps extends InputNumberProps {
  viewMode?: boolean;
}

export const InputNumber: React.FC<IInputNumberProps> = ({ viewMode, ...props }) => {
  return (
    <AntInputNumber
      size="large"
      {...props}
      disabled={viewMode}
      bordered={!viewMode}
      className={`custom-number-input ${viewMode ? 'view-mode' : ''} ${props.className}`}
    />
  );
};
