import { NormalizedObject } from '../common/commonTypes';
import { userActions, UserActionTypes } from './userActionTypes';
import { User } from './userType';

const initialState = {};

export function usersByOrgId(state: NormalizedObject<User[]> = initialState, action: userActions) {
  switch (action.type) {
    case UserActionTypes.SET_ORGANIZATION_MEMBERS:
      return { [action.id]: action.payload };

    case UserActionTypes.UPDATE_ORGANIZATION_MEMBER:
      if(action.orgId && state[action.orgId]) {
        return {
          [action.orgId]: state[action.orgId].map((member: User) => {
            if (member.id === action.payload.id) {
              return action.payload;
            } else {
              return member;
            }
          }),
        };
      }
      return state;

    case UserActionTypes.ADD_ORGANIZATION_MEMBER:
      return { [action.orgId]: state[action.orgId].concat(action.payload) };

    case UserActionTypes.DELETE_ORGANIZATION_MEMBER:
      return {
        [action.orgId]: state[action.orgId].filter((user: User) => user.id !== action.userId),
      };

    default:
      return state;
  }
}
