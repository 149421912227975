import { Action } from 'redux';
import { CapabilityOffer, CapabilityOfferStats } from '../capabilities/capabilityType';
import { NormalizedState } from '../common/commonTypes';
import { EventSummary, RegistrationSummary } from './eventType';

export enum EventActionTypes {
  SET_ACTIVE_EVENTS = 'SET_ACTIVE_EVENTS',
  SET_CLOSED_EVENTS = 'SET_CLOSED_EVENTS',
  ADD_EVENT = 'ADD_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
  SET_EVENT_DETAILS = 'SET_EVENT_DETAILS',
  SET_REGISTRATIONS = 'SET_REGISTRATIONS',
  SET_CAPABILITIES = 'SET_CAPABILITIES',
  UPDATE_MY_OFFERS = 'UPDATE_MY_OFFERS',
  UPDATE_REGISTRATION_STATUS = 'UPDATE_REGISTRATION_STATUS',
  UPDATE_OFFER_STATE = 'UPDATE_OFFER_STATE'
}

export interface SetActiveEventsAction extends Action {
  type: EventActionTypes.SET_ACTIVE_EVENTS;
  payload: NormalizedState<EventSummary>;
}

export interface SetClosedEventsAction extends Action {
  type: EventActionTypes.SET_CLOSED_EVENTS;
  payload: NormalizedState<EventSummary>;
}

export interface AddEventAction extends Action {
  type: EventActionTypes.ADD_EVENT;
  payload: EventSummary;
}

export interface UpdateEventAction extends Action {
  type: EventActionTypes.UPDATE_EVENT;
  payload: EventSummary;
}

export interface SetEventDetails extends Action {
  type: EventActionTypes.SET_EVENT_DETAILS;
  payload: EventSummary;
}

export interface SetRegistrationsAction extends Action {
  type: EventActionTypes.SET_REGISTRATIONS;
  orgId: number;
  eventId: number;
  payload: RegistrationSummary[];
}

export interface SetCapabilitiesAction extends Action {
  type: EventActionTypes.SET_CAPABILITIES;
  payload: CapabilityOfferStats[];
}

export interface UpdateMyOffersAction extends Action {
  type: EventActionTypes.UPDATE_MY_OFFERS;
  payload: CapabilityOffer;
}

export interface UpdateRegistrationStatus extends Action {
  type: EventActionTypes.UPDATE_REGISTRATION_STATUS;
  payload: RegistrationSummary[];
  eventId: number;
  orgId: number;
}

export interface UpdateOfferStateAction extends Action {
  type: EventActionTypes.UPDATE_OFFER_STATE;
  payload: CapabilityOffer[];
}

export type eventActions =
  | SetActiveEventsAction
  | SetClosedEventsAction
  | AddEventAction
  | UpdateEventAction
  | SetEventDetails
  | SetRegistrationsAction
  | UpdateRegistrationStatus
  | SetCapabilitiesAction
  | UpdateMyOffersAction
  | UpdateOfferStateAction;
