import { FormInstance } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormItem, useForm } from '../../../common/components/ant/form';
import { Input } from '../../../common/components/ant/input';
import { Modal } from '../../../common/components/ant/modal';
import { OrganizationDeatils } from '../../orgTypes';

interface OrgActionsProps {
  showModal: boolean;
  handleSubmit: (values: OrganizationDeatils, form: FormInstance) => void;
  handleCancel: () => void;
  initialValues?: OrganizationDeatils;
  title: string;
}

const OrgActions: React.FC<OrgActionsProps> = ({
  showModal,
  handleSubmit,
  handleCancel,
  initialValues,
  title,
}) => {
  const [form, setForm] = useState<FormInstance<any>>();

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      onOk={() => {
        if (form) {
          form.submit();
        }
      }}
      title={title}
    >
      <OrgActionsForm initialValues={initialValues} handleSubmit={handleSubmit} setFrom={setForm} />
    </Modal>
  );
};

export default OrgActions;

interface OrgActionsFormProps {
  handleSubmit: (values: OrganizationDeatils, form: FormInstance) => void;
  initialValues?: OrganizationDeatils;
  setFrom: React.Dispatch<React.SetStateAction<FormInstance<any> | undefined>>;
}
const OrgActionsForm: React.FC<OrgActionsFormProps> = ({
  initialValues,
  handleSubmit,
  setFrom,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  useEffect(() => {
    setFrom(form);
  }, [form]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={(values) => handleSubmit(values, form)}
    >
      <FormItem
        name="code"
        label={t('organizations.code')}
        rules={[{ required: true, message: t('common.validation.field-empty') }]}
      >
        <Input
          viewMode={initialValues && initialValues.code ? true : false}
          className="with-background"
        />
      </FormItem>
      <FormItem
        name="name"
        label={t('organizations.name')}
        rules={[{ required: true, message: t('common.validation.field-empty') }]}
      >
        <Input />
      </FormItem>
      <FormItem name="address" label={t('organizations.address')}>
        <Input />
      </FormItem>
      <FormItem name="phone" label={t('organizations.phoneNumber')}>
        <Input />
      </FormItem>
    </Form>
  );
};
