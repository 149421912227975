import { Action } from 'redux';
import { Capability } from './capabilityType';

export enum CapabilityActionTypes {
    SET_ORG_CAPABILITES = 'SET_ORG_CAPABILITES',
    ADD_ORG_CAPABILITY = 'ADD_ORG_CAPABILITY',
    UPDATE_ORG_CAPABILITY = 'UPDATE_ORG_CAPABILITY',
    DELETE_ORG_CAPABILITY = 'DELETE_ORG_CAPABILITY'
}

export interface AddOrgCapabilityAction extends Action {
  type: CapabilityActionTypes.ADD_ORG_CAPABILITY;
  payload: Capability;
  id: number;
}

export interface EditOrgCapabilityAction extends Action {
  type: CapabilityActionTypes.UPDATE_ORG_CAPABILITY;
  payload: Capability;
  id: number;
}

export interface SetOrgCapabilityAction extends Action {
  type: CapabilityActionTypes.SET_ORG_CAPABILITES;
  payload: Capability[];
  id: number;
}

export interface DeleteOrgCapabilityAction extends Action {
  type: CapabilityActionTypes.DELETE_ORG_CAPABILITY;
  orgId: number;
  capabilityId: number;
}


export type ValueSetActions = SetOrgCapabilityAction | AddOrgCapabilityAction | EditOrgCapabilityAction | DeleteOrgCapabilityAction;
