import { CaretRightOutlined } from '@ant-design/icons';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoggedInUser } from '../../../auth/authState';
import { setExpandedKeys, setSelectedOrg } from '../../../organizations/orgActions';
import {
  useExpandedKeys,
  useOrganizations,
  useSelectedOrgId,
} from '../../../organizations/orgState';
import { roleTypes } from '../../../users/userType';
import { RoleChecker } from '../../../utils/roleChecker';
import TreeSelect from '../ant/treeSelect';

const OrgTreeSelect: React.FC = () => {
  const selectedOrgId = useSelectedOrgId();
  const expandedKeys = useExpandedKeys();
  const organizations = useOrganizations();
  const { t } = useTranslation();
  const user = useLoggedInUser();

  const rootOrgIds = organizations.allIds.filter((id: number) => {
    const parentId = organizations.byId[id].parentId;
    if (parentId) {
      return !organizations.byId[parentId];
    } else {
      return true;
    }
  });

  useEffect(() => {
    let defaultValue = 0;
    if (!selectedOrgId && organizations && organizations.allIds.length) {
      if (user.primaryOrgId) {
        defaultValue = user.primaryOrgId;
      } else {
        defaultValue = organizations.allIds[0];
      }
      setExpandedKeys([defaultValue]);
      setSelectedOrg(defaultValue);
    }
  }, [organizations, selectedOrgId]);

  const normalizeOrgTree = (orgId: number): any[] => {
    const org = organizations.byId[orgId];
    if (org.children) {
      return org.children.map((orgId) => {
        return {
          title: organizations.byId[orgId].name,
          value: orgId,
          children: normalizeOrgTree(orgId),
        };
      });
    }

    return [];
  };

  const treeData = rootOrgIds.map((orgId) => {
    return {
      title: organizations.byId[orgId].name,
      value: orgId,
      children: normalizeOrgTree(orgId),
    };
  });

  const onExpand = (expandedKeys: any[]) => {
    setExpandedKeys(expandedKeys);
  };

  return (
    <div className="org-tree-container">
      <div style={{ paddingRight: '8px' }}>
        <TreeSelect
          value={selectedOrgId ? selectedOrgId : undefined}
          placeholder={t('organizations.select')}
          bordered={false}
          style={{ minWidth: '230px' }}
          className="org-tree-select"
          dropdownClassName="dropdown"
          onTreeExpand={onExpand}
          treeExpandedKeys={expandedKeys}
          treeData={treeData}
          switcherIcon={<CaretRightOutlined />}
          dropdownMatchSelectWidth={false}
          showSearch={true}
          listHeight={356}
          onChange={(value: number) => setSelectedOrg(value)}
          filterTreeNode={(search: any, item: any) => {
            return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
          }}
        />
      </div>
      {selectedOrgId ? (
        <>
          <RoleChecker roles={[roleTypes.ADMIN]}>
            <div id="create-child-container" style={{ paddingLeft: '8px' }}></div>
          </RoleChecker>
          <div id="org-details-container" style={{ paddingLeft: '8px' }}></div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default OrgTreeSelect;
