import { FormInstance } from 'antd';
import React from 'react';
import { FormItem } from '../../../../common/components/ant/form';
import { Input } from '../../../../common/components/ant/input';
import { RoleSelect } from '../../../../common/components/roleSelect';

interface AccountsFormProps {
  id: number;
  editMode: boolean;
  form?: FormInstance;
}
const AccountsForm: React.FC<AccountsFormProps> = ({ id, editMode }) => {
  return (
    <>
      <div className="table-cell alias-cell">
        <div className="table-cell-content">
          <FormItem name="alias" rules={[{ required: true }]}>
            <Input id={'alias' + id} viewMode={!editMode} />
          </FormItem>
        </div>
      </div>
      <div className="table-cell roles-cell">
        <div className="table-cell-content">
          <FormItem name="roles" rules={[{ required: true }]}>
            <RoleSelect id={'roles' + id} viewMode={!editMode} />
          </FormItem>
        </div>
      </div>
    </>
  );
};

export default AccountsForm;
