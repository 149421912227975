import { createStore, applyMiddleware, combineReducers, Store, Action } from 'redux';
import thunk from 'redux-thunk';
import { RootState } from './commonTypes';
import { persistReducer, persistStore } from 'redux-persist';
import { auth } from '../auth/authReducer';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { organizations } from '../organizations/orgReducer';
import { usersByOrgId } from '../users/usersByOrgIdReducer';
import { valueSets } from '../valueSets/valueSetReducer';
import { capabilitiesByOrgId } from '../capabilities/capabilitiesByOrgReducer';
import { activeEvents } from '../events/activeEventsReducer';
import {closedEvents} from '../events/closedEventsReducer';
import {registrationsByOrg} from '../events/registrationsByOrgReducer';
import {eventCapabilities} from '../events/eventCapabilitiesReducer';
import { authActions, AuthActionTypes } from '../auth/authActionTypes';
import { clearOrgs } from '../utils/normalizr';

// WhiteList: save into local storage
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const rootReducer = (state: any, action: authActions) => {
  if (action.type === AuthActionTypes.USER_LOGOUT) {
    clearOrgs();
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const appReducer = combineReducers({
  auth,
  organizations,
  usersByOrgId,
  capabilitiesByOrgId,
  activeEvents,
  valueSets,
  closedEvents,
  registrationsByOrg,
  eventCapabilities
});

const persistorReducer = persistReducer(persistConfig, rootReducer);

export const store: Store<RootState, Action<any>> = createStore(
  persistorReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export const persistor = persistStore(store);
