import { FormInstance } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateButton } from '../../../common/components/ant/button';
import { addAccount, getOrganizationMembers } from '../../../users/userActions';
import { useOrganizationMembers } from '../../../users/userState';
import { roleTypes } from '../../../users/userType';
import { RoleChecker } from '../../../utils/roleChecker';
import { handleErrors } from '../../../utils/validation';
import { useSelectedOrgId } from '../../orgState';
import MemberList from './memberList';
import MembersActions from './memberActions';
import './memberStyle.scss';

const Members: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const selectedOrgId = useSelectedOrgId();
  const members = useOrganizationMembers(selectedOrgId);

  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedOrgId) {
      getOrganizationMembers(selectedOrgId);
    }
  }, [selectedOrgId]);

  const handleSubmit = (userValues: any, values: any, form: FormInstance) => {
    let account = { ...values, orgId: selectedOrgId };
    delete account.id;

    const json = {
      ...userValues,
      accounts: [account],
    };

    return addAccount(json, selectedOrgId, account)
      .then((response) => {
        handleCancel();
        return Promise.resolve();
      })
      .catch((error) => {
        handleErrors(error.response.data, t, form, setErrorMessage);
        return Promise.reject();
      });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="members-container">
      <MembersActions
        showModal={showModal}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        errorMessage={errorMessage}
      />

      <div className="table-container">
        <RoleChecker roles={[roleTypes.ADMIN]}>
          <div className="new-item-container">
            <CreateButton
              onClick={() => setShowModal(true)}
              text={t('organizations.addMember.action')}
            />
          </div>
        </RoleChecker>
        <MemberList users={members} />
      </div>
    </div>
  );
};

export default Members;
