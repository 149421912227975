import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FormInstance, Steps } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentType } from '../../../common/commonTypes';
import Alert from '../../../common/components/ant/alert';
import { Button } from '../../../common/components/ant/button';
import { Form, FormItem, useForm } from '../../../common/components/ant/form';
import { Input } from '../../../common/components/ant/input';
import { Modal } from '../../../common/components/ant/modal';
import { RoleSelect } from '../../../common/components/roleSelect';
import { UserForm } from '../../../users/components/userActions';
import { getUsers } from '../../../users/userService';
import { User, initialUser, Membership } from '../../../users/userType';
import { useValidateMessages } from '../../../utils/useValidateMessages';

interface MembersActionsProps {
  showModal: boolean;
  handleSubmit: (userValues: any, values: any, form: FormInstance<any>) => Promise<any>;
  handleCancel: () => void;
  errorMessage?: string;
}

const MembersActions: React.FC<MembersActionsProps> = ({
  showModal,
  handleSubmit,
  handleCancel,
  errorMessage,
}) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [user, setUser] = useState<User>(initialUser);
  const steps = ['Find User', 'User details', 'Account'];

  const { Step } = Steps;

  const next = () => {
    setStepNumber(stepNumber + 1);
  };

  const back = () => {
    setStepNumber(stepNumber - 1);
  };

  const cancel = () => {
    setStepNumber(0);
    handleCancel();
  };

  const submit = (user: User, values: Membership, form: FormInstance<any>) => {
    handleSubmit(user, values, form).then(() => {
      setStepNumber(0);
    });
  };

  const components: ComponentType = {
    0: <FindUserForm next={next} setUser={setUser} handleCancel={cancel} />,
    1: <UserDataForm user={user} next={next} back={back} setUser={setUser} handleCancel={cancel} />,
    2: <AccountForm user={user} back={back} handleCancel={cancel} handleSubmit={submit} />,
  };

  return (
    <Modal
      visible={showModal}
      footer={null}
      destroyOnClose={true}
      className="details-modal"
      closable={false}
      width={620}
      title={
        <Steps current={stepNumber} type="navigation">
          {steps.map((step: string) => (
            <Step key={step} title={step} />
          ))}
        </Steps>
      }
    >
      <div className={`error-container ${errorMessage ? 'visible' : ''}`}>
        {errorMessage && <Alert message={errorMessage} type="error" />}
      </div>
      <div>{components[stepNumber]}</div>
    </Modal>
  );
};

export default MembersActions;

interface FindUserFormProps {
  setUser: (value: React.SetStateAction<User>) => void;
  next: () => void;
  handleCancel: () => void;
}

const FindUserForm: React.FC<FindUserFormProps> = ({ setUser, next, handleCancel }) => {
  const { t } = useTranslation();

  const findUserByEmail = (email: string) => {
    getUsers('email=' + email).then((response: User[]) => {
      if (response.length) {
        setUser(response[0]);
      } else {
        setUser({ ...initialUser, email });
      }
      next();
    });
  };
  return (
    <Form layout="vertical" size="large" onFinish={(values) => findUserByEmail(values.email)}>
      <FormItem
        name="email"
        label={t('common.email')}
        rules={[{ required: true }, { type: 'email' }]}
      >
        <Input />
      </FormItem>
      <div className="member-actions-modal-footer">
        <Button size="large" onClick={() => handleCancel()}>
          {t('common.cancel')}
        </Button>
        <Button size="large" type="primary" htmlType="submit">
          {t('common.next')} <ArrowRightOutlined />
        </Button>
      </div>
    </Form>
  );
};

interface UserDataFormProps {
  user: User;
  setUser: (value: React.SetStateAction<User>) => void;
  next: () => void;
  back: () => void;
  handleCancel: () => void;
}
const UserDataForm: React.FC<UserDataFormProps> = ({ user, setUser, next, back, handleCancel }) => {
  const { t } = useTranslation();

  return (
    <>
      <UserForm
        initialValues={user}
        handleSubmit={(values, form) => {
          setUser({ ...user, ...values });
          next();
        }}
        hideEmailField={true}
        editMode={user.id ? false : true}
      >
        <div className="member-actions-modal-footer">
          <Button size="large" onClick={() => handleCancel()}>
            {t('common.cancel')}
          </Button>
          <div className="step-actions">
            <Button onClick={() => back()} size="large" type="primary">
              <ArrowLeftOutlined /> {t('common.back')}
            </Button>
            <Button size="large" type="primary" htmlType="submit">
              {t('common.next')} <ArrowRightOutlined />
            </Button>
          </div>
        </div>
      </UserForm>
    </>
  );
};

interface AccountFormProps {
  user: User;
  back: () => void;
  handleCancel: () => void;
  handleSubmit: (user: User, values: Membership, form: FormInstance<any>) => void;
}
const AccountForm: React.FC<AccountFormProps> = ({ user, back, handleCancel, handleSubmit }) => {
  const { t } = useTranslation();
  const validateMessages = useValidateMessages();
  const [form] = useForm();
  return (
    <Form
      layout="vertical"
      size="large"
      form={form}
      validateMessages={validateMessages}
      onFinish={(values) => {
        handleSubmit(user, values, form);
      }}
    >
      <FormItem name="alias" label={t('account.alias')} rules={[{ required: true }]}>
        <Input />
      </FormItem>

      <FormItem name="roles" label={t('account.roles')} rules={[{ required: true }]}>
        <RoleSelect />
      </FormItem>
      <div className="member-actions-modal-footer">
        <Button size="large" onClick={() => handleCancel()}>
          {t('common.cancel')}
        </Button>
        <div className="step-actions">
          <Button onClick={() => back()} size="large" type="primary">
            <ArrowLeftOutlined />
            {t('common.back')}
          </Button>
          <Button size="large" type="primary" htmlType="submit">
            {t('common.submit')}
          </Button>
        </div>
      </div>
    </Form>
  );
};
