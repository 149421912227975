import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './loginScreenStyle.scss';
import { Form, FormItem, useForm } from '../../common/components/ant/form';
import { Input } from '../../common/components/ant/input';
import { Button } from '../../common/components/ant/button';
import Alert from '../../common/components/ant/alert';
import { resetPassword } from '../authService';
import LoginScreen from './loginScreen';
import { handleErrors } from '../../utils/validation';

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LoginScreen>
      <div className="login-form">
        <h1 className="login-title">{t('login.forgotpassword')}</h1>
        <ForgotPwForm />
      </div>
    </LoginScreen>
  );
};

export default ResetPassword;

const ForgotPwForm: React.FC = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation();

  const [form] = useForm();

  const location = useLocation();
  const search = location.search;
  const history = useHistory();

  let params = new URLSearchParams(search);

  const handleSubmit = (values: any) => {
    const json = {
      ...values,
      email: params.get('email'),
      resetToken: params.get('token'),
    };
    resetPassword(json)
      .then(() => {
        history.push('/login');
      })
      .catch((error) => {
        handleErrors(error.response.data, t, form, setErrorMsg);
      });
  };

  return (
    <>
      <div className={`error-container ${errorMsg ? 'visible' : ''}`}>
        {errorMsg && <Alert message={errorMsg} type="error" />}
      </div>
      <Form form={form} onFinish={handleSubmit} layout="vertical" size="large">
        <FormItem
          name="password"
          label={t('login.password')}
          rules={[{ required: true, message: t('common.validation.field-empty') }]}
        >
          <Input type="password" />
        </FormItem>

        <div className="login-button-container">
          <Button htmlType="submit" size="large" type="primary">
            {t('common.save')}
          </Button>
        </div>
      </Form>
    </>
  );
};
