import { useSelector } from 'react-redux';
import { RootState } from '../common/commonTypes';
import { ValueSet } from './valueSetType';

export const useValueSets = () =>
  useSelector((state: RootState) => {
    return state.valueSets;
  });

export const useValueSetItems = (code: string) => {
  const valueSets = useValueSets();

  const valueSet = valueSets.find((valueSet: ValueSet) => valueSet.code === code);

  if(valueSet) {
    return valueSet.items
  }

  return [];
}