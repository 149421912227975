import axios from 'axios';
import { convertStringToDate } from '../utils/propertyConverter';
import { Capability, CapabilityDateProperties } from './capabilityType';

const restURL = process.env.REACT_APP_API_ENDPOINT;

export function addCapability(capability: Capability) {
  return axios.post(`${restURL}/capabilities`, capability).then((response) => {
    convertStringToDate(response.data, CapabilityDateProperties);
    return response.data;
  });
}

export function editCapability(capability: Capability) {
  return axios.put(`${restURL}/capabilities/${capability.id}`, capability).then((response) => {
    convertStringToDate(response.data, CapabilityDateProperties);
    return response.data;
  });
}

export function deleteCapability(capabilityId: number) {
  return axios.delete(`${restURL}/capabilities/${capabilityId}`).then((response) => {
    return response.data;
  });
}

export function getOrganizationCapabilites(id: number) {
  return axios.get(`${restURL}/organizations/${id}/capabilities`).then((response) => {
    convertStringToDate(response.data, CapabilityDateProperties);
    return response.data;
  });
}