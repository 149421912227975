import axios from 'axios';
import { CapabilityDateProperties } from '../capabilities/capabilityType';
import { convertDateToString, convertStringToDate } from '../utils/propertyConverter';
import { Membership, User, UserDateProperties } from './userType';

const restURL = process.env.REACT_APP_API_ENDPOINT;

export function getUsers(query?: string) {
  return axios.get(`${restURL}/users${query ? '?' + query : ''}`).then((response) => {
    convertStringToDate(response.data, UserDateProperties);
    return response.data;
  });
}

export function createUser(user: User) {
  convertDateToString(user, UserDateProperties, false);
  return axios.post(`${restURL}/users`, user).then((response) => {
    return response.data;
  });
}

export function getOrganizationMembers(id: number) {
  return axios.get(`${restURL}/users/?orgIds=` + id).then((response) => {
    convertStringToDate(response.data, UserDateProperties);
    return response.data;
  });
}

export function deleteAccount(accountId: number, userId: number) {
  return axios.delete(`${restURL}/users/${userId}/accounts/${accountId}`).then((response) => {
    convertStringToDate(response.data, UserDateProperties);
    return response.data;
  });
}

export function editAccount(account: Membership) {
  return axios
    .put(`${restURL}/users/${account.userId}/accounts/${account.id}`, account)
    .then((response) => {
      return response.data;
    });
}

export function addAccount(userId: number, account: Membership) {
  return axios
    .post(`${restURL}/users/${userId}/accounts`, account)
    .then((response) => {
      return response.data;
    });
}


export function editUser(user: User) {
  convertDateToString(user, UserDateProperties, false);
  return axios
    .put(`${restURL}/users/${user.id}`, user)
    .then((response) => {
      convertStringToDate(response.data, UserDateProperties);
      return response.data;
    });
}

export function getUserDetails(userId: number) {
  return axios
    .get(`${restURL}/users/${userId}`)
    .then((response) => {
      convertStringToDate(response.data, UserDateProperties);
      if(response.data.capabilities) {
        convertStringToDate(response.data.capabilities, CapabilityDateProperties);
      }
      return response.data;
    });
}