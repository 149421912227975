import { Modal as AntModal, ModalProps } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface IModalProps extends ModalProps {
  children: ReactNode;
}

export const Modal = (props: IModalProps) => {
  const { t } = useTranslation();
  return (
    <AntModal
      okText={t('common.submit')}
      cancelText={t('common.cancel')}
      cancelButtonProps={{ size: 'large', className: 'custom-button cancel' }}
      okButtonProps={{ size: 'large', className: 'custom-button save' }}
      destroyOnClose={true}
      maskClosable={false}
      {...props}
    >
      {props.children}
    </AntModal>
  );
};

export const { confirm } = AntModal;
