import { Input as AntInput } from 'antd';
import { InputProps, TextAreaProps } from 'antd/lib/input';
import './antStyle.scss';

interface IInputProps extends InputProps {
  viewMode?: boolean;
}

export const Input: React.FC<IInputProps> = ({ viewMode, ...props }) => {
  return (
    <AntInput
      size="large"
      {...props}
      disabled={viewMode}
      bordered={!viewMode}
      className={`custom-input ${viewMode ? 'view-mode' : ''} ${props.className} `}
    />
  );
};

interface ITextAreaProps extends TextAreaProps {
  viewMode?: boolean;
}
export const TextArea: React.FC<ITextAreaProps> = ({ viewMode, ...props }) => {
  const { TextArea } = AntInput;

  return (
    <TextArea
      size="large"
      disabled={viewMode}
      bordered={!viewMode}
      {...props}
      className={`custom-textarea ${viewMode ? 'view-mode' : ''} ${props.className}`}
    />
  );
};
