import { User } from "../users/userType";

export interface AuthState {
  status: AuthStatus;
  user: User;
}

export interface UserCredentials {
  username: string;
  password: string;
}

export enum AuthStatus {
  NOT_AUTHENTICATED,
  CREDENTIALS_SUBMITTED,
  PENDING_AUTHENTICATION,
  AUTHENTICATED,
  AUTHENTICATION_ERROR,
  SERVER_ERROR,
}
