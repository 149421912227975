import axios from 'axios';
import { logOut } from '../auth/authActions';
import i18n from '../i18n';

export const initInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        const token = localStorage.getItem('token');

        if (token) {
          config.headers.Authorization = token;
        }
      }
      let language: string | null = i18n.language;
      if(!language) {
        language = localStorage.getItem('i18nextLng')
      }
      if (language) {
        config.headers['Accept-Language'] = language;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => {
      const token = localStorage.getItem('token');
      const tokenFromBE = response.headers.authorization;
      if(token && tokenFromBE && token !== tokenFromBE) {
        console.log("refresh auth token");
        localStorage.setItem('token', tokenFromBE)
      }
      
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        logOut();
        window.location.href = '/login';
      }
      console.log(error);
      return Promise.reject(error);
    },
  );
};
