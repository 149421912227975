import { useTranslation } from "react-i18next"

export const useValidateMessages = () => {
    const { t } = useTranslation();

    return {
        required: t('common.validation.field-empty'),
        types: {
          email: t('common.validation.invalid-email'),
        },
        string: {
          min: t('login.error.password-length')
        }
    }
}
