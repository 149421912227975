import { roles } from '../../users/userType';
import { Select, Option } from './ant/select';

export const RoleSelect = (props: any) => (
  <Select mode="multiple" {...props} size="large">
    {roles.map((role: string) => (
      <Option key={role} value={role}>
        {role}
      </Option>
    ))}
  </Select>
);
