import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import React from 'react';
import { Button } from './components/ant/button';
import { DatePicker } from './components/ant/DatePicker';

interface MonthFilterProps {
  onChange: (value: Moment) => void;
  value?: Moment;
  isHistory?: boolean;
}
export const MonthFilter: React.FC<MonthFilterProps> = ({ onChange, value, isHistory }) => {
  return (
    <div className="month-picker-container">
      <Button
        icon={<LeftOutlined />}
        onClick={() => {
          if (value) {
            onChange(value.clone().subtract(1, 'months'));
          }
        }}
      />
      <DatePicker
        picker="month"
        className="month-picker"
        value={value}
        onChange={onChange}
        format="YYYY-MMMM"
        allowClear={isHistory ? false : true}
      />
      <Button
        icon={<RightOutlined />}
        disabled={isHistory && value && value.diff(moment(new Date()), 'months') === 0}
        onClick={() => {
          if (value) {
            onChange(value.clone().add(1, 'months'));
          }
        }}
      />
    </div>
  );
};
