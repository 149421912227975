import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, FormItem, useForm } from '../../common/components/ant/form';
import { Select, Option } from '../../common/components/ant/select';
import { useOrganizations, useSelectedOrgId } from '../../organizations/orgState';
import { getActiveEvents, updateEvent } from '../eventActions';
import { useActiveEvents } from '../eventState';
import { EventSummary } from '../eventType';
import { CreateButton } from '../../common/components/ant/button';
import EventList from './eventList';
import { MonthFilter } from '../../common/monthFilter';
import { Input } from '../../common/components/ant/input';
import { useResetFormOnCloseModal } from '../../utils/useResetForm';
import { Modal } from '../../common/components/ant/modal';

const ActiveEvents: React.FC = () => {
  const selectedOrgId = useSelectedOrgId();
  const activeEvents = useActiveEvents();
  const history = useHistory();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [orgIds, setOrgIds] = useState<number[]>([]);
  const [event, setEvent] = useState<EventSummary>();
  const [filter, setFilter] = useState<{ text: string; month?: Moment }>({
    text: '',
    month: undefined,
  });

  useEffect(() => {
    if (selectedOrgId) {
      getActiveEvents(selectedOrgId);
    }
  }, [selectedOrgId]);

  const handleSubmit = (values: any) => {
    updateEvent({ ...event, ...values })
      .then(() => {
        history.push(`events/${event!.id}/org/${values.organizerOrgId}`);
      })
      .catch(() => {
        setShowModal(false);
      });
  };

  const search = () => {
    const text = filter.text;
    const date = filter.month;
    let filteredEvents = activeEvents.allIds;
    if (text) {
      const lowerCaseText = text.toLocaleLowerCase();

      filteredEvents = filteredEvents.filter((id: number) => {
        const event = activeEvents.byId[id];
        if (
          event.summary.toLowerCase().includes(lowerCaseText) ||
          (event.description && event.description.toLowerCase().includes(lowerCaseText))
        ) {
          return true;
        }
        return false;
      });
    }

    if (date) {
      const from = date.clone().startOf('month');
      const to = date.clone().endOf('month');
      filteredEvents = filteredEvents.filter((id: number) => {
        const event = activeEvents.byId[id];
        if (event.plannedStart >= from && event.plannedEnd && event.plannedEnd <= to) {
          return true;
        }
        return false;
      });
    }

    return filteredEvents;
  };

  const filteredEventListId = search();

  function setMonth(date: Moment) {
    setFilter({ ...filter, month: date });
  }

  const setChangeOrganizerParameters = (event: EventSummary, orgIds: number[]) => {
    setEvent(event);
    setOrgIds(orgIds);
    setShowModal(true);
  };

  return (
    <>
      <div className="table-container event-list">
        <div className="active-events-filter-container" style={{ display: 'flex' }}>
          <CreateButton
            onClick={() => history.push(`events/org/${selectedOrgId}`)}
            text={t('events.actions.create')}
          />

          <div className="filters">
            <div className="text-filter">
              <Input
                onChange={(e) => setFilter({ ...filter, text: e.target.value })}
                placeholder="Search text..."
              />
            </div>
            <MonthFilter value={filter.month} onChange={setMonth} />
          </div>
        </div>
        <ChangeOrganizerAction
          showModal={showModal}
          handleSubmit={handleSubmit}
          handleCancel={() => setShowModal(false)}
          orgIds={orgIds}
        />

        <EventList
          events={filteredEventListId.map((id) => activeEvents.byId[id])}
          setChangeOrganizerParameters={setChangeOrganizerParameters}
        />
      </div>
    </>
  );
};

export default ActiveEvents;

interface ChangeOrganizerActionProps {
  showModal: boolean;
  handleCancel: () => void;
  handleSubmit: (values: any) => void;
  orgIds: number[];
}
const ChangeOrganizerAction: React.FC<ChangeOrganizerActionProps> = ({
  showModal,
  handleCancel,
  handleSubmit,
  orgIds,
}) => {
  const organizations = useOrganizations();
  const [form] = useForm();
  const { t } = useTranslation();
  useResetFormOnCloseModal({ form, showModal });
  return (
    <Modal
      visible={showModal}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      title={t('events.changeOrgIdToMeTitle')}
    >
      <Form form={form} size="large" layout="vertical" onFinish={(values) => handleSubmit(values)}>
        <FormItem label={t('events.organizer')} name="organizerOrgId" rules={[{ required: true }]}>
          <Select>
            {orgIds.map((id) => {
              return (
                <Option key={id} value={id}>
                  {organizations.byId[id].name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};
