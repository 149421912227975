import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { RootState } from '../../common/commonTypes';
import { AuthStatus } from '../authTypes';

interface AuthRouteProps extends RouteProps {
  type: string;
}

const AuthRoute = ({ type, ...children }: AuthRouteProps) => {
  const authStatus = useSelector((state: RootState) => state.auth.status);

  if (type === 'guest' && authStatus === AuthStatus.AUTHENTICATED) {
    return <Redirect to="/events" />;
  } else if (type === 'private' && authStatus !== AuthStatus.AUTHENTICATED) {
    return <Redirect to="/login" key="reload" />;
  }

  return <Route {...children} />;
};

export default AuthRoute;
